import React, { useEffect, useState } from "react";
import { useAuth } from "../auth/AuthContext";
import { useNavigate } from "react-router-dom";

import toast from "react-hot-toast";
import PulseLoader from "react-spinners/PulseLoader";

import SalaryHeader from "../components/Salary/SalaryHeader";
import SalaryTable from "../components/Salary/SalaryTable";
import SalaryDetails from "../components/Salary/SalaryDetails";
import SalaryStatus from "../components/Salary/SalaryStatus";
import SalaryCorrections from "../components/Salary/SalaryCorrections";
import SalaryApprove from "../components/Salary/SalaryApprove";
import SalaryShiftOverlay from "../components/Salary/SalaryShiftOverlay";
import CustomShiftOverlay from "../components/Salary/CustomShiftOverlay";

import dayjs from "dayjs";
import axios from "axios";
import { Company } from "../types/company";


import { SalaryData } from "../types/salaryData";
import { Shift } from "../types/shifts";
import { Driver } from "../types/driver";
import { Correction } from "../types/correction";

const Salary = () => {
  const { isAuthenticated, user } = useAuth();
  const navigate = useNavigate();
  const [companyAkareNo, setCompanyAkareNo] = useState<string[] | null>(null);
  const [companies, setCompanies] = useState<Company[]| null>(null);
  const [drivers, setDrivers] = useState<Driver[] | null>(null);
  const [driverNo, setDriverNo] = useState<number | null>(null);
  const [date, setDate] = useState(dayjs().set("date", 1).format("YYYY-MM-DD"));
  const [salaryData, setSalaryData] = useState<SalaryData | null>(null);
  /* const [corrections, setCorrections] = useState<Correction[] | null>(null); */
  const [status, setStatus] = useState(0);
  const [isProcessing, setIsProcessing] = useState(false);
  const [comment, setComment] = useState("");
  const [adminComment, setAdminComment] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedShiftId, setSelectedShiftId] = useState<string |null>(null);
  const [isLockingPasses, setIsLockingPasses] = useState(false);
  const [customShiftDriverNo, setCustomShiftDriverNo] = useState<number | null>(null);

  useEffect(() => {
    async function getCompanies() {
      if (!date) return;

      const response = await axios.get("/company/available?startdate=" + date);
      setCompanies(response.data);
    }
    getCompanies();
  }, [date]);

  useEffect(() => {
    if (user?.rights === 5) setDriverNo(user.driverNo);
  }, [user]);

  useEffect(() => {
    if (!user || !isAuthenticated) {
      navigate("/signin");
      return;
    }
  }, [isAuthenticated, navigate, user]);

  useEffect(() => {
    async function getDrivers() {
      if(companyAkareNo && companyAkareNo.length > 0){
        const response = await axios.get(
          "/drivers?akareNo=" +  companyAkareNo.join(",") + "&startdate=" + date,
        );
        const newDrivers: Driver[] = response.data
        setDrivers(newDrivers);
      }
      
    }
    if (companyAkareNo && companyAkareNo.length !== 0 && date && user && user.rights !== 5) getDrivers();
  }, [companyAkareNo, date, user]);

  async function getDriverData() {
    if (!date || (companyAkareNo && companyAkareNo.length === 0) || !driverNo) return;

    setLoading(true);

    try {
      const response = await axios.get(
        `/salary?driverNo=${driverNo}&akareNo=${companyAkareNo}&startdate=${date}`,
      );
      if (response.data) {
        setSalaryData(response.data);
        if (response.data.approvedSalary) {
          setComment(response.data.approvedSalary.comment || "");
          setAdminComment(response.data.approvedSalary.adminComment || "");
        }
        /* getCorrections(); */
      }
    } catch (err) {
      console.log(err);
      setSalaryData(null);
      /* setCorrections([]); */
    }
    setLoading(false);
    return;
  }
  useEffect(() => {
    getDriverData();
  }, [driverNo, companyAkareNo, date]);

/*   const getCorrections = async () => {
    try {
      const correctionsRes = await axios.get(
        `/corrections?driverNo=${driverNo}&companyId=${companyAkareNo}&startdate=${date}`,
      );
      if (correctionsRes.data) {
        setCorrections(correctionsRes.data);
      }
    } catch (err) {
      console.log(err);
      setCorrections([]);
    }
  };

  const approveCorrection = async (correction:Correction) => {
    try {
      const response = await axios.post("/corrections", {
        correction: correction,
        date: date,
      });
      if (response.status === 200) {
        getCorrections();
      }
    } catch (err) {
      console.log(err);
    }
  };

  const ignoreCorrection = async (correction:Correction) => {
    try {
      await axios.post("/corrections/ignore", correction);

      getCorrections();
    } catch (err) {
      console.log(err);
    }
  };

  const removeCorrection = async (correctionId:number) => {
    try {
      await axios.delete(`/corrections/${correctionId}`);
      getCorrections();
    } catch (err) {
      console.log(err);
    }
  }; */

  const inspectShift = (id:string) => {
    setSelectedShiftId(id);
  };

  const addCustomShift = async (driverNo:number) => {
    try {
      // Open Modal wirh <NewShift />
      setCustomShiftDriverNo(driverNo);
    } catch (err) {
      console.log(err);
    }
  };

  const closeNewShift = () => {
    getDriverData();
    setCustomShiftDriverNo(null);
  };

  const closeInspectShift = () => {
    getDriverData();
    setSelectedShiftId(null);
  };

  const onToggle = async (id:string) => {
    try {
      const response = await axios.post(`/shift/lock/${id}`);
      if (response.status === 200) {
        getDriverData();
        // alert("notification!")
      }
    } catch (err) {
      console.log(err);
    }
  };

  const lockPasses = async () => {
    if(salaryData){
    try {
      setIsLockingPasses(true);

        const ids = salaryData.monthData
        .flat()
        .map((item) => item.id)
        .filter((item) => item !== "");
        const response = await axios.post(`/shifts/lock/all`, ids);
        
        if (response.status === 200) {
          getDriverData();
          toast.success("All shifts locked!");
        }
        setIsLockingPasses(false);
      } catch (err) {
        console.log(err);
      }
    }
  };

  const addShift = async (shift:Shift) => {
    try {
      const response = await axios.post("/shifts", shift);
      if (response.status === 200) {
        getDriverData();
      }
    } catch (err) {
      console.log(err);
    }
  };


  const goToNextDriver = (direction: number) => {
    if (!drivers || drivers.length === 0 || driverNo === null) {
      return;
    }
  
    const driverIndex = drivers.findIndex((d) => String(d.driverNo) === String(driverNo));
    if (driverIndex === -1) {
      return;
    }
  
    let newIndex = driverIndex + direction;
  
    if (newIndex < 0) {
      newIndex = drivers.length - 1;
    } else if (newIndex >= drivers.length) {
      newIndex = 0;
    }
  
    const newDriverNo = drivers[newIndex].driverNo;
    setDriverNo(newDriverNo);
  };

  const approveSalary = async () => {
    if(salaryData){
      try {
        const response = await axios.post("/salary/approve", {
          id: salaryData.approvedSalary?.ID,
          akareNo: companyAkareNo,
          comment: comment,
          adminComment: adminComment,
          date: date,
          totTime: salaryData.totWorkedData.totTid,
          breakTime: salaryData.totWorkedData.totBreaktime,
          workedTime: salaryData.totWorkedData.hoursWorked,
          ob1Time: salaryData.obHours.ob1,
          ob2Time: salaryData.obHours.ob2,
          cash: salaryData.cash.cash,
          totAmCash: salaryData.cash.totAmCash,
        });
        
        if (response.status === 200) {
          getDriverData();
        }
      } catch (err) {
        console.log(err);
      }
      setIsProcessing(false);
    };
    if (!isAuthenticated) {
      return null;
    }
  }
  return (
    <>
      <div className="mx-auto max-w-7xl px-4 lg:px-8 pt-4">
        <SalaryHeader
          setCompanyAkareNo={setCompanyAkareNo}
          setDriverNo={setDriverNo}
          setDate={setDate}
          setStatus={setStatus}
          companyAkareNo={companyAkareNo}
          companies={companies}
          driverNo={driverNo}
          drivers={drivers}
          date={date}
          status={status}
        />
        {loading ? <PulseLoader color="#36d7b7" size={5} /> : null}
        {!salaryData ? null : (
          <>
            <SalaryTable
              salaryData={salaryData}
/*            approvedCorrections={corrections.accepted_corrections}
              removeCorrection={removeCorrection} */
              goToNextDriver={goToNextDriver}
              userRights={user && user?.rights}
            />
            <SalaryStatus
              approvedByDriver={salaryData.approved?.approvedByDriver}
              approvedByAdmin={salaryData.approved?.approvedByAdmin}
              completed_userID={salaryData.approved?.completed_userID} 
            />
            <SalaryApprove
              approvedSalary={salaryData.approvedSalary}
              approveSalary={approveSalary}
              setComment={setComment}
              comment={comment}
              setAdminComment={setAdminComment}
              adminComment={adminComment}
              date={date}
            />
{/*             <SalaryCorrections
              corrections={corrections}
              ignoreCorrection={ignoreCorrection}
              approveCorrection={approveCorrection} 
              isProcessing={isProcessing}
            /> */}
          </>
        )}
        {!salaryData ? null : (
          <SalaryDetails
            data={salaryData.monthTemp}
            onToggle={onToggle}
            lockPasses={lockPasses}
            inspectShift={inspectShift}
            closeInspectShift={closeInspectShift}
            selectedShiftId={selectedShiftId}
            isLockingPasses={isLockingPasses}
            addShift={addShift}
            driverNo={driverNo}
            addCustomShift={addCustomShift}
          />
        )}
      </div>

      {selectedShiftId ? (
        <SalaryShiftOverlay
          selectedShiftId={selectedShiftId}
          closeInspectShift={closeInspectShift}

        
        />
      ) : null}
      {customShiftDriverNo ? (
        <CustomShiftOverlay
          customShiftDriverNo={customShiftDriverNo}
          closeNewShift={closeNewShift}
        />
      ) : null}
    </>
  );
};

export default Salary;
