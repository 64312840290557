import React from "react";
import { List, ListItem, Icon , Col} from '@tremor/react';
import {  TrashIcon } from "@heroicons/react/24/outline";
import { Break } from "../../types/shifts";

type BreakListProps = {
    breaks: Break[] | null,
    removeBreak: (tmpId:string) => void
}


const BreaksList = (props:BreakListProps) => {

    const {
        breaks,
        removeBreak
    } = props


    if(breaks && breaks.length === 0) return null;

    return (
        <Col numColSpan={4}>
            <div className="mb-6">
                <List>
                    <ListItem>
                        <span className="min-w-1">Start</span>
                        <span className="min-w-1">Slut</span>
                        <span className="min-w-2">&nbsp;</span>
                    </ListItem>
                    {breaks &&
                        breaks.map((b, index) => (
                            <ListItem key={index}>
                                <span>{b.starttime}</span>
                                <span>{b.endtime}</span>
                                {
                                    (b.manuallyReported) ? (
                                    <span>
                                        <Icon icon={TrashIcon}  
                                            className="hover:cursor-pointer text-red-300" 
                                            variant="simple" tooltip="Ta bort" 
                                            size="sm" 
                                            onClick={()=>removeBreak(b.tmpId)} />
                                    </span>
                                    ) : <span></span> 
                                }
                                
                            </ListItem>
                        ))
                    }
                </List>
            </div>
        </Col>
    );
}

export default BreaksList;