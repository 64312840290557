import React from 'react';
import { Card, Metric, Text } from "@tremor/react";


type TotalMetricProps = {
    title: string,
    value:string,
    undertext?:string
}

const TotalMetric = (props:TotalMetricProps) => {

    const {
        title,
        value,
        undertext
    } = props

    return (
        <>
            <Card className="max-w-xs mx-auto mb-3 mt-3" decoration="left" decorationColor="indigo">
                <Text>{title}</Text>
                <Metric>{value}</Metric>
                { 
                    (undertext && undertext != "") ?
                        <p className="text-tremor-default text-tremor-content dark:text-dark-tremor-content">{undertext}</p> 
                    : null
                }
            </Card>
        </>
    )
}

export default TotalMetric;