import React, { ChangeEvent, FormEvent, useState } from "react";
import { Button } from "@tremor/react";
import { ArrowUpOnSquareIcon } from "@heroicons/react/24/outline";
import { OnChangeType } from "../types/onChangeType";
import { Report } from "../types/shifts";

type FileUploaderProps = {
  type: string,
  id: string,
  success: (type: OnChangeType, name: keyof Report) => void,
}

const FileUploader = (props: FileUploaderProps) => {
  const { type, id, success } = props;

  const [file, setFile] = useState<File | null>(null);
  const [fileIsUploaded, setFileIsUploaded] = useState<boolean>(false);
  const [fileNameUploaded, setFileNameUploaded] = useState<string>("");

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const url = `${process.env.REACT_APP_BASE_URL_API}/shifts/attachments/${type}/${id}`;
    
    fetch(url, {
      method: "POST",
      body: formData,
    })
      .then(response => {
        if (response.ok) {
          const uploadedFileName = (formData.get(type) as File)?.name || '';
          setFileIsUploaded(true);
          setFileNameUploaded(uploadedFileName);
          setFile(null);

          // Update the success callback with the uploaded file name
          if (type === "adm") {
            success(uploadedFileName, "errorImgAdm");
          } else if (type === "vehicle") {
            success(uploadedFileName, "errorImgVehicle");
          }
        } else {
          console.log("File upload failed");
        }
      })
      .catch(err => {
        console.error("Error during file upload", err);
      });
  };

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.currentTarget.files;
    if (files && files.length > 0) {
      setFile(files[0]);
      setFileIsUploaded(false); // Reset the upload status when a new file is selected
    }
  };

  return (
    <form
      onSubmit={onSubmit}
      method="post"
      encType="multipart/form-data"
      className="mb-6"
    >
      <input
        id={`file-input-${type}`}
        type="file"
        onChange={onChange}
        name={type}
        hidden
      />
      <label
        htmlFor={`file-input-${type}`}
        className="cursor-pointer mr-6 border p-2 rounded-md border-slate-300 hover:bg-gray-100 hover:border-gray-400 text-sm"
      >
        {file?.name ? file.name : "Välj fil"}
      </label>

      <input type="hidden" name="type" value={type} />

      {file && file?.name ? (
        <Button
          size="xs"
          className=""
          disabled={!file}
          icon={ArrowUpOnSquareIcon}
          type="submit"
        >
          Skicka in
        </Button>
      ) : fileIsUploaded ? (
        <p className="text-slate-400 my-2 text-sm">{fileNameUploaded} har laddats upp</p>
      ) : null}
    </form>
  );
};

export default FileUploader;