import React from 'react';  
import {Text ,Card, NumberInput,Button} from '@tremor/react'

type ChangeAkareModalProps = {
    success: (akareNo:number) => void,
    failure: () => void
}

const ChangeAkareModal = (props:ChangeAkareModalProps) => {
   const {
    success,
    failure
   } = props
    const [akareNo,setAkareNo] = React.useState<number | null>(null); 


    return (
        <div className="fixed w-full h-full top-0 left-0 flex justify-center items-center">            
            <div className="fixed inset-0 bg-stone-900 w-full h-full bg-opacity-25" onClick={()=>failure()}></div>
            <Card className="max-w-xs mx-auto bg-white p-4" >
                <Text className="mb-2">Change to åkare:</Text>
                <NumberInput size={2} enableStepper={false} placeholder="Åkare Nummer" onValueChange={v => setAkareNo(Number(v))} max={999} />
                <Button className="mt-2 float-right" size="xs" variant="primary" color="emerald" onClick={()=>success(akareNo!)}>Submit</Button>
            </Card>
        </div>
    );
}

export default ChangeAkareModal;
