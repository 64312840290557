import React from 'react';
import {Dialog, DialogPanel} from '@tremor/react';
import Shift from '../../pages/Shift';

type SalaryShiftOverlayProps ={
    selectedShiftId: string,
    closeInspectShift: () => void,
}

const SalaryShiftOverlay = (props:SalaryShiftOverlayProps) => {
   
    const {
        selectedShiftId,
        closeInspectShift,
    } = props


    return (
        <Dialog open={true} onClose={(val) => closeInspectShift()} static={true}>
            <DialogPanel className="max-w-5xl">
                <div >
                    <Shift selectedShiftId={selectedShiftId} closeInspectShift={closeInspectShift}/>
                </div>
            </DialogPanel>
      </Dialog>
    )
}

export default SalaryShiftOverlay;