import React from "react";
import {
  SearchSelect,
  SearchSelectItem,
  Text,
  Col,
  DatePicker,
  Grid,
  MultiSelect,
  MultiSelectItem,
  DatePickerValue,
  SelectItem,
} from "@tremor/react";
import { useAuth } from "../../auth/AuthContext";
import dayjs from "dayjs";
import { Company } from "../../types/company";
import { Driver } from "../../types/driver";

type SalaryHeaderProps = {
  setCompanyAkareNo: (companyNo:string[] | null) => void,
  setDriverNo: (driverNo:number | null) => void,
  setDate: (date:string) => void,
  setStatus: (status:number) => void,
  companyAkareNo: string[] | null,
  driverNo: number | null,
  drivers: Driver[] |null,
  date: string,
  companies: Company[] | null,
  status: number

}


const SalaryHeader = (props:SalaryHeaderProps) => {

  const{
    setCompanyAkareNo,
    setDriverNo,
    setDate,
    setStatus,
    companyAkareNo,
    driverNo,
    drivers,
    date,
    companies,
    status
  } = props;

  const { user } = useAuth();
  const onChangeDate = (value:DatePickerValue) => {
    let date = dayjs(value);
    date = date.date(1);
    let newDate = "";
    newDate = date.format("YYYY-MM-DD");

    setDate(newDate);
  };

  const onCompanyChange = (value:string[]) => {
    setCompanyAkareNo(value);
    if (user && user.rights !== 5) {
      setDriverNo(null);
    }
  };

  return (
    <Grid numItems={3} numItemsLg={3} numItemsMd={3} numItemsSm={3}>
      <Col numColSpan={3} numColSpanMd={1} numColSpanSm={3} numColSpanLg={1}>
        <div className="mt-4 mb-2  mr-6">
          <Text className="mb-2">Månad</Text>
          <DatePicker
            value={!date ? new Date() : new Date(date)}
            displayFormat="yyyy-MM-dd"
            onValueChange={onChangeDate}
          />
          <p className="mt-4 text-tremor-label text-tremor-content dark:text-dark-tremor-content">
            Välj månad
          </p>
        </div>
      </Col>
      <Col numColSpan={3} numColSpanMd={1} numColSpanSm={3} numColSpanLg={1}>
        <div className="mt-4 mb-2  mr-6">
          <Text className="mb-2">Företag</Text>
          <MultiSelect placeholderSearch="Sök" placeholder="Välj"
            value={companyAkareNo ? companyAkareNo : []}
            onValueChange={(value) => onCompanyChange(value)}
          >

            {companies && companies.map((company, idx) => (

              <MultiSelectItem key={idx} value={company.akareNo.toString()}>
                {company.haldaName}
              </MultiSelectItem>
            ))}
          </MultiSelect>
          <p className="mt-4 text-tremor-label text-tremor-content dark:text-dark-tremor-content">
            Välj företag
          </p>
        </div>
      </Col>

      <Col numColSpan={3} numColSpanMd={1} numColSpanSm={3} numColSpanLg={1}>
        {user && user.rights === 5 || status ? null : (
          <div className="mt-4 mb-2  mr-6">
            <Text className="mb-2">Förare</Text>
            <SearchSelect
              placeholder="Välj"
              value={driverNo ? driverNo.toString() : ""}
              onValueChange={(value) => setDriverNo(parseInt(value))}
            >
              {drivers && drivers.map((driver, idx) => (
                <SearchSelectItem key={idx} value={driver.driverNo?.toString() || ""}>
                  {driver.name} - {driver.driverNo}
                </SearchSelectItem>
              ))}
            </SearchSelect>
            <p className="mt-4 text-tremor-label text-tremor-content dark:text-dark-tremor-content">
              Välj förare
            </p>
          </div>
        )}
      </Col>
    </Grid>
  );
};

export default SalaryHeader;
