import React, { useEffect, useState } from "react";
import {
  TextInput,
  Button,
  Grid,
  Col,
  Textarea,
  Callout,
  Divider,
  Text,
} from "@tremor/react";
import {
  formatTime,
  convertToCurrency,
  calculateTotalHours,
  validateTime,
  formatDate,
} from "../../lib/utils";
import { useAuth } from "../../auth/AuthContext";
import {
  ExclamationTriangleIcon,
  ArrowPathIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import "react-datepicker/dist/react-datepicker.css";
import FileUploader from "../FileUploader";
import ChangeAkareModal from "./ChangeAkareModal";
import dayjs from "dayjs";
import TooltipMarker from "../Tooltip";
import { Tooltip } from "react-tooltip";
import BreaksList from "./BreaksList";
import AddBreak from "./AddBreak";
import { Break, Shift,Report } from "../../types/shifts";
import { OnChangeType } from "../../types/onChangeType";
type FullShiftReportProps = {
  shift: Shift,
  onChange:(value:OnChangeType, name: keyof Report) => void,
  updateShift: () => void,
  createShift:() => void,
  removeShift:() => void,
/*   removeBreak: (breakId:number) => void, */
  removeAttachment: (type:string,id:string) => void,
  success: (value: OnChangeType, name: keyof Report) => void,
  changeAkare: (driverNo:number) => void,
  updateBreaks: (updatedBreaks:Break[]) => void,
  unlockShift: (id:string) => void,

}

const FullShiftReport = (props:FullShiftReportProps) => {

  const {
    shift,
    onChange,
    updateShift,
    createShift,
    removeShift,
    removeAttachment,
    success,
    changeAkare,
    updateBreaks,
    unlockShift
  } = props

  const { report, breaks, registered } = shift;
  const { user } = useAuth();

  const [initialTotal, setInitialTotal] = React.useState(
    report.totMoneyMadeInkTaxUI,
  );
  const [initialExpenses, setInitialExpenses] = React.useState(report.expenses);
  const [initialStarttime, setInitialStarttime] = React.useState(
    formatTime(report.starttimeUI ?? "No Date"),
  );
  const [initialEndtime, setInitialEndtime] = React.useState(
    formatTime(report.endtimeUI ?? "No date"),
  );
  const [changedTotal, setChangedTotal] = React.useState(false);
  const [expensesChanged, setExpensesChanged] = React.useState(false);
  const [timeChangedStart, setStartTimeChanged] = React.useState(false);
  const [timeChangedEnd, setEndTimeChanged] = React.useState(false);

  const [isModalOpen, setIsModalOpen] = React.useState(false);


  const [inputStartTime, setInputStartTime] = useState<string>(
    report.starttimeUI ? report.starttimeUI.slice(11, 16) : "00:00"
  );
  const [inputEndTime, setInputEndTime] = useState<string>(
    report.endtimeUI ? report.endtimeUI.slice(11, 16) : "00:00"
  );
  /**
     * Check if the total amount of money made has changed

     */

  useEffect(() => {
    if (report.totMoneyMadeInkTaxUI !== initialTotal) {
      setChangedTotal(true);
    } else {
      setChangedTotal(false);
    }
    if (report.expenses !== initialExpenses) {
      setExpensesChanged(true);
    } else {
      setExpensesChanged(false);
    }

    if (formatTime(report.starttimeUI ?? "No Date") !== initialStarttime) {
      setStartTimeChanged(true);
    } else {
      setStartTimeChanged(false);
    }
    if (formatTime(report.endtimeUI ?? "No Date") !== initialEndtime) {
      setEndTimeChanged(true);
    } else {
      setEndTimeChanged(false);
    }
  }, [
    report.totMoneyMadeInkTaxUI,
    report.expenses,
    report.starttimeUI,
    report.endtimeUI,
  ]);

  const isDisabled = () => {
    // Early return if no user
    if (!user) return false;

    const hasInvalidTimeFormat = 
        !validateTime(formatTime(report.starttimeUI ?? "No Date")) ||
        !validateTime(formatTime(report.endtimeUI ?? "No Date"));
    if (hasInvalidTimeFormat) return true;

    // For regular users (not admin)
    if (user.rights !== 5) {
        // Require admin comment when values are changed
        if ((changedTotal || expensesChanged) && 
            (report.admin_comment === "" || report.admin_comment === null)) {
            return true;
        }
        return false;
    }

    // For admin users (rights === 5)
    if (!report.locked) {
        // Require explanation in errorReportAdm for changed totals or time
        if ((changedTotal || timeChangedStart || timeChangedEnd)) {
            // Check if errorReportAdm is empty or less than 3 characters
            if (!report.errorReportAdm || report.errorReportAdm.length < 3) {
                return true;
            }
        }

        if (expensesChanged && report.errorReportVehicle === "") {
            return true;
        }
    }

    // If registered, admin can't modify
    if (registered && user.rights === 5) {
        return true;
    }

    return false;
  };

  const removeBreak = (tmpId:string) => {
    let newBreaks: Break[] = breaks.filter((b) => b.tmpId !== tmpId);
    updateBreaks(newBreaks);
  };

  const addBreak = (starttime:string, endtime:string) => {
    if (!starttime || !endtime) return;
    const newBreak:Break = {
      starttime: starttime,
      endtime: endtime,
      manuallyReported: true,
      tmpId: Date.now().toString(),
    }
    const newBreaks:Break[] = [...breaks,newBreak];
    updateBreaks(newBreaks);
  };

  const [totalHours, setTotalHours] = useState(calculateTotalHours(shift));
  useEffect(() => {
    const tempShift = {
      ...shift,
      report: {
        ...shift.report,
        starttimeUI: shift.report.startdate + "T" + inputStartTime,
        endtimeUI: shift.report.enddate + "T" + inputEndTime
      },
      breaks: breaks
    };
    console.log('tempShift', tempShift);
    const newTotal = calculateTotalHours(tempShift);
    setTotalHours(newTotal);
  }, [inputStartTime, inputEndTime, breaks]);

  if (!report) return null;

  return (
    <div className="pb-12">
      <Tooltip id="my-tooltip" />
      <Grid numItems={4} className="gap-2">
        <Col numColSpan={2}>
          <div className="mt-4 mb-4 ">
            <Text>
              Jag har kört fordon:
              <TooltipMarker place="right" contentKey="carNo" />
            </Text>
            {/* <TextInput value={report.carNo} disabled /> */}
            <div className="font-light text-sm">{report.carNo}</div>
          </div>
        </Col>
        <Col numColSpan={2}>
          <div className="mt-4 mb-4 ">
            <Text>
              Rapport nr:
              <TooltipMarker place="right" contentKey="rapportNo" />
            </Text>
            <div className="font-light text-sm">{report.workshiftNo}</div>
          </div>
        </Col>
        <Col numColSpan={2}>
          <div className="mt-4 mb-4 ">
            <Text>
              Startdatum:
              <TooltipMarker place="right" contentKey="startdatum" />
            </Text>
            <div className="font-light text-sm">
              {formatDate(report.startdate?.toString() ?? "No Date")}
            </div>
          </div>
        </Col>
        <Col numColSpan={2}>
          <div className="mt-4 mb-4 ">
            <Text>
              Slutdatum:
              <TooltipMarker place="right" contentKey="slutdatum" />
            </Text>
            <div className="font-light text-sm">
              {formatDate(report.enddate?.toString() ?? "No Date")}
            </div>
          </div>
        </Col>
        <Col numColSpan={2}>
          <div className="mt-4 mb-6 ">
            <Text>
              Start (uppgift från taxameter):
              <TooltipMarker place="right" contentKey="tid" />
            </Text>
            <div className="font-light text-sm">
              {formatTime(report.starttime?.toString() ?? "Tid saknas")}
            </div>
          </div>
        </Col>
        <Col numColSpan={2}>
          <div className="mt-4 mb-6 ">
            <Text>
              Slut (uppgift från taxameter):
              <TooltipMarker place="right" contentKey="tid" />
            </Text>
            <div className="font-light text-sm">
              {formatTime(report.endtime?.toString() ?? "Tid saknas")}
            </div>
          </div>
        </Col>

        <Col numColSpan={2}>
          <div className="mt-4 mb-6 ">
            <Text>Start:</Text>
            {user &&
            <TextInput
            error={!validateTime(formatTime(inputStartTime))}
            errorMessage={"Var vänlig att ange tiden i formatet: TT:MM"}
            value={formatTime(inputStartTime)}
            disabled={(report.locked || (registered && user.rights === 5))}
            onValueChange={(value) => {
              setInputStartTime(value)
              const formattedTime = report.startdate + "T" + value + ":00.000Z"
              onChange(formattedTime, "starttimeUI")
            }}
          
            />
          }
          </div>
        </Col>
        <Col numColSpan={2}>
          <div className="mt-4 mb-6 ">
            <Text>Slut:</Text>
            {user &&
            <TextInput
              error={!validateTime(formatTime(inputEndTime))}
              errorMessage={"Var vänlig att ange tiden i formatet: TT:MM"}
              value={formatTime(inputEndTime)}
              disabled={report.locked || (registered && user.rights === 5)}
              onValueChange={(value) => {
                setInputEndTime(value)
                const formattedTime = report.enddate + "T" + value + ":00.000Z"
                onChange(formattedTime, "endtimeUI")
              }}
            />
}
          </div>
        </Col>

        <Col numColSpan={4}>
          <Divider>Rast</Divider>
        </Col>

        <BreaksList breaks={breaks} removeBreak={removeBreak} />
        <AddBreak addBreak={addBreak} />

        <Col numColSpan={4}>
          <div className="mt-4 mb-6 ">
            <Text>
              Arbetad tid :
              <TooltipMarker place="right" contentKey="timeworked" />
            </Text>
            <div className="font-light text-sm">
              {totalHours}
            </div>
          </div>
        </Col>
        <Col numColSpan={2}>
          <div className="mt-4 mb-6 ">
            <Text>Totalt inkört (uppgift från taxameter) :</Text>
            <div className="font-light text-sm">
              {convertToCurrency(Number(report.totMoneyMadeInkTax))}
            </div>
          </div>
        </Col>
        <Col numColSpan={2}>
          <div className="mt-4 mb-6 ">
            <Text>
              Totalt inkört :
              <TooltipMarker place="right" contentKey="totaltinkort" />
            </Text>
            {user && 
            <TextInput
              type="text"
              value={report.totMoneyMadeInkTaxUI?.toString() ?? "NaN"}
              disabled={(report.locked || registered) && user.rights === 5}
              onValueChange={(value) => onChange(value, "totMoneyMadeInkTaxUI")}
            />
          }
          </div>
        </Col>
        <Col numColSpan={2}>
          <div className="mt-4 mb-6 ">
            <Text>
              Redovisas kontant :
              <TooltipMarker place="right" contentKey="redovisas" />
            </Text>
            <div className="font-light text-sm">
              {convertToCurrency(Number(report.totAmCash))}
            </div>
          </div>
        </Col>

        <Col numColSpan={2}>
          <div className="mt-4 mb-6 ">
            <Text>
              Utlägg :
              <TooltipMarker place="right" contentKey="utlagg" />
            </Text>
            {user &&
            <TextInput
              type="text"
              defaultValue={report.expenses?.toString() ?? "NaN"}
              onValueChange={(value) => onChange(value, "expenses")}
              disabled={(report.locked || registered) && user.rights === 5}
            />
          }
          </div>
        </Col>
        <Col numColSpan={2}>
          <div className="mt-4 mb-6 ">
            <Text>Kvar att redovisa kontant :</Text>
            <div className="font-light text-sm">
              {convertToCurrency(
                report.totAmCash ?? 0 - (report.expenses ?? 0),
              )}
            </div>
          </div>
        </Col>

        <Col numColSpan={4}>
          {breaks.length === 0 && calculateTotalHours(shift) > 6 ? (
            <Callout
              className="mt-4 mb-4"
              title="Reasons needed"
              icon={ExclamationTriangleIcon}
              color="yellow"
            >
              Du har jobbat mer än 6 timmar men inte tagit någon rast, ange
              orsak till den uteblivna rasten i felanmälan ekonomi eller fyll i
              den rast du haft i rastfälten.
            </Callout>
          ) : null}
          {user && user.rights === 5 &&
          !report.locked &&
          (changedTotal || timeChangedStart || timeChangedEnd) &&
          (!report.errorReportAdm || report.errorReportAdm.length < 3) ? (
            <Callout
              className="mt-4 mb-4"
              title="Förklara varför du har ändrat värdena"
              icon={ExclamationTriangleIcon}
              color="rose"
            >
              Du har ändrat värden. Berätta varför
              nedanför under felanmälan ekonomi.
            </Callout>
          ) : null}
          {user && user.rights === 5 &&
          !report.locked &&
          expensesChanged &&
          report.errorReportVehicle === "" ? (
            <Callout
              className="mt-4 mb-4"
              title="Förklara varför du har ändrat värdena"
              icon={ExclamationTriangleIcon}
              color="yellow"
            >
              Du har redovisat att du gjort utlägg under passet. Bifoga en bild
              på kvittot under felanmälan ekonomi.
            </Callout>
          ) : null}
          {user && user.rights !== 5 &&
          (changedTotal || expensesChanged) &&
          (report.admin_comment === "" || report.admin_comment === null) ? (
            <Callout
              className="mt-4 mb-4"
              title="Förklara varför du har ändrat värdena"
              icon={ExclamationTriangleIcon}
              color="orange"
            >
              Du har ändrat några värden. Ange en förklaring i avsnittet.
            </Callout>
          ) : null}
        </Col>
        <Col numColSpan={4}>
          <Text className="">Avvikelser / Felrapportering</Text>
          <div className="mt-4 mb-6">
            <Text>Ekonomi/Lön/ADM :</Text>
            {user &&
            <Textarea
            placeholder="Ange orsak"
            value={report.errorReportAdm || ""}
            onChange={(e) => onChange(e.target.value, "errorReportAdm")}
            disabled={
              user.rights === 1 || user.rights === 2 || user.rights === 6 
              ? false 
              : (report.locked || (registered && user.rights === 5))
            }
            />
          }
          </div>
          {user &&( report.locked ||
          (registered && user.rights === 5) ||
          user.rights === 2 ||
          user.rights === 1) ? null : (
            <FileUploader type="adm" id={report.ID ?? "No ID"} success={success} />
          )}
          {report.errorImgAdm ? (
            <>
              <a
                download
                href={
                  `${process.env.REACT_APP_BASE_URL_API}/uploads/` +
                  report.errorImgAdm
                }
              >
              {/\.(jpg|jpeg|png|gif|bmp|webp|svg)$/i.test(report.errorImgAdm) ? (
                <img
                  alt=""
                  src={
                    `${process.env.REACT_APP_BASE_URL_API}/uploads/` + report.errorImgAdm
                  }
                  className="w-[92px] border"
                />
              ) : (
                <span className="text-blue-500 underline block mr-4">
                  {report.errorImgAdm}
                </span>
              )}
              </a>
              {user &&
              <Button
              size="xs"
              icon={TrashIcon}
              color="red"
              className="mt-4"
              disabled={
                report.locked ||
                (registered && user.rights === 5) ||
                user.rights === 2 ||
                user.rights === 1
              }
              onClick={() => removeAttachment("adm", report.ID ?? "No ID")}
              >
                Ta bort
              </Button>
              }
            </>
          ) : null}
        </Col>

        <Col numColSpan={4}>
          <Text>Felanmälan</Text>
          <div className="mt-4 mb-6">
            <Text>Fordon / Teknik / Tvätthall :</Text>
            {user &&
            <Textarea
            placeholder="Ange orsak"
            className="mb-6"
            value={report.errorReportVehicle || ""}
            onChange={(e) => onChange(e.target.value, "errorReportVehicle")}
            disabled={
              user.rights === 1 || user.rights === 2 || user.rights === 6 
              ? false 
              : (report.locked || (registered && user.rights === 5))
            }
            />
          }
            {user && (report.locked ||
            (registered && user.rights === 5) ||
            user.rights === 2 ||
            user.rights === 1) ? null : (
              <FileUploader type="vehicle" id={report.ID ?? "No ID"} success={success} />
            )}
            {report.errorImgVehicle ? (
              <>
                <img
                  alt=""
                  src={
                    `http://localhost:3005/api/uploads/` +
                    report.errorImgVehicle
                  }
                  className="w-[92px] border "
                />
                {user &&
                <Button
                disabled={
                  report.locked ||
                  (registered && user.rights === 5) ||
                  user.rights === 2 ||
                  user.rights === 1
                }
                className="mt-2"
                onClick={() => removeAttachment("vehicle", report.ID ?? "No ID")}
                >
                  Ta bort
                </Button>
                }
              </>
            ) : null}
          </div>
        </Col>
        {user && (user.rights === 2 || user.rights === 1) ? (
          <Col numColSpan={4}>
            <Text>Admin notering</Text>
            <div className="mt-4 mb-6">
              <Text>Notering från admin :</Text>
              <Textarea
                placeholder="Ange orsak"
                className="mb-6"
                value={report.admin_comment || ""}
                onChange={(e) => onChange(e.target.value, "admin_comment")}
              />
            </div>
          </Col>
        ) : null}

        <Col numColSpan={4}>
          <div className="flex flex-row justify-end">
            {user && ((report.locked || registered) && user.rights === 5) ? null : (
              <>
                {user && (user.rights === 2 || user.rights === 1) ? (
                  <Button
                    className="mr-6 cursor-pointer"
                    icon={ArrowPathIcon}
                    size="xs"
                    variant="light"
                    color="orange"
                    onClick={() => setIsModalOpen(!isModalOpen)}
                  >
                    Byta Åkare
                  </Button>
                ) : null}
                {user && ((user.rights === 2 || user.rights === 1) && registered) ? <Button variant="secondary" className="ml-2 mr-2" onClick={()=>unlockShift(report.ID ?? "No ID")}>Lås upp skift</Button> : null }
                  <Button 
                    onClick={updateShift} 
                    disabled={isDisabled()}
                    className={isDisabled() ? 'opacity-50' : ''}
                  >
                    Godkänn
                  </Button>
                </>
              )}
          </div>
        </Col>
      </Grid>
      {isModalOpen ? (
        
        <ChangeAkareModal
          success={(akare:number) => {
            setIsModalOpen(false);
            changeAkare(akare);
          }}
          failure={() => setIsModalOpen(false)}
        />
      ) : null}
    </div>
  );
};

export default FullShiftReport;
