import { Card, TextInput, Text, Button} from "@tremor/react";
import {useState,useEffect, FormEvent, ChangeEvent} from "react";
import {APP_ROUTES} from '../config/routes';
import { useAuth } from '../auth/AuthContext';
import { useNavigate } from "react-router-dom";
import { TabGroup, TabList, Tab, TabPanels, TabPanel } from "@tremor/react";
import { UserIcon, TruckIcon } from '@heroicons/react/24/outline'
import axios from "axios";
import React from "react";

const SignIn = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [driverNo, setDriverNo] = useState("");
    const [error, setError] = useState<boolean>(false);
    
    const navigate = useNavigate();
    const {isAuthenticated,signIn} = useAuth();

    const onChange = (e:ChangeEvent<HTMLInputElement>) => {
        const {name, value} = e.target;
        if(name === "email") {
            setEmail(value);
        } else if(name === "password") {
            setPassword(value);
        }else if(name === "driverNo"){
            setDriverNo(value)
        }
    }

    useEffect(() => { 
        if(isAuthenticated){
          navigate(APP_ROUTES.SHIFTS)
        }
      }
      ,[isAuthenticated,navigate]);

    const handleSubmit = async (event:FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        
        // const username = (data.get('email')) ? data.get('email') : data.get('driverNo') 

        try {
            const response = await axios.post('/signin', { email , password : data.get('password'), driverNo });
            if(response.status === 200){
                signIn(response.data);
                navigate(APP_ROUTES.SHIFTS);
            }else{
                setError(true)
            }
        } catch (error) {
            console.log(error)
            //show message to user
            setError(true)
        }
    }

    const onIndexChange = (v:number) => {
        setEmail("")
        setPassword("")
        setDriverNo("")
        setError(false)
    }

    const validate = () => {
        return ( (email === "" && password === "") || (driverNo === "" && password === ""));
    }

    return (
        <div className="flex justify-center items-center w-full h-screen">
            <Card className="max-w-xs mx-auto p-8" >
                <form onSubmit={handleSubmit}>
                <TabGroup onIndexChange={(v)=>onIndexChange(v)}>
                    <TabList className="mt-8 flex flex-row justify-center items-center" variant="line">
                        <Tab icon={TruckIcon}>Förare</Tab>
                        <Tab icon={UserIcon}>Användare</Tab>
                    </TabList>
                    <TabPanels>
                        <TabPanel>
                            <div className="mt-6 mb-2">
                                <Text className="mb-2">Förarnummer</Text>
                                <TextInput name="driverNo" onChange={(e)=>onChange(e)} placeholder="123 412" type="text" value={driverNo}/>
                            </div>
                            <div className="mt-4 mb-2">
                                <Text className="mb-2">Lösenord</Text>
                                <TextInput name="password" onChange={(e)=>onChange(e)} placeholder="***********" type="password" value={password}/>
                            </div>
                            <Text className="text-red-400 text-xs text-center">{ (error) ? "Invalid email or password" : "" }</Text>
                        </TabPanel>
                        <TabPanel>
                            <div className="mt-6 mb-2">
                                <Text className="mb-2">Email</Text>
                                <TextInput name="email" onChange={(e)=>onChange(e)} placeholder="user@email.com" type="text"  value={email} />
                            </div>
                            <div className="mt-4 mb-2">
                                <Text className="mb-2">Lösenord</Text>
                                <TextInput name="password" onChange={(e)=>onChange(e)} placeholder="***********" type="password" value={password} />
                            </div>
                            <Text className="text-red-400 text-xs text-center">{ (error) ? "Felaktiga inloggningsuppgifter var god försök igen" : "" }</Text>
                        </TabPanel>
                    </TabPanels>
                    </TabGroup>
                    <Button disabled={validate()} className="mt-6 w-full" size="xs" type="submit" >Logga in</Button>
                </form>
            </Card>
        </div>
    );
}

export default SignIn;