import React,{ useState , useEffect} from "react";
import { Title, Switch } from "@tremor/react";
import axios from 'axios';
import { Company } from "../../types/company";
import { User } from "../../types/user";
import { UserOrDriver } from "../../types/driver";

type AdminSectionProps = {
    /* onSectionChange: (value:string,name:string) => void, */
    companySelection: any,
    onCompanySelection: (id:string,value:boolean) => void,
    setCompanySelection: (selection: { [key: number]: boolean }) => void,
    user: UserOrDriver | null,
    userId: string |null | undefined
}

const AdminSection = (props:AdminSectionProps) => {

    const { 
       /*  onSectionChange,  */
        companySelection, 
        onCompanySelection, 
        setCompanySelection, 
        user, 
        userId 
      } = props;


    const [companies, setCompanies] = useState<Company []|null>(null);

    const getCompanies = async () => {
        const response = await axios.get('/company/all');
        if(response.data.length > 0){
            const allCompanies: Company[] = response.data;
            setCompanies(allCompanies);

            if(userId){
                const usercompaniesRes = await axios.get('/company/user?userId='+userId);
                if(usercompaniesRes.data.length > 0){
                    let temp:{[key:string]:boolean} = {};
                    allCompanies.forEach((company) => {
                        usercompaniesRes.data.includes(company.id) ? temp[company.id] = true : temp[company.id] = false;
                    })
                    setCompanySelection(temp);
                }
            }else{
                let temp:{[key:string]:boolean} = {};
                response.data.forEach((company:Company) => {
                    temp[company.id] = false;
                })
                setCompanySelection(temp);
            }
        }   
    }

    useEffect(() => {
        getCompanies();
    },[])

    return (
        <div className="mt-6 mb-4">
            <Title className="mb-4">Företag</Title>
            { companies ?
                companies.map((company,idx) => {
                    return (
                        <div key={idx} className="flex flex-row items-center mt-2">
                            <Switch 
                                id="switch" 
                                name={company.akareNo.toString()} 
                                checked={companySelection[company.akareNo]} 
                                onChange={(value)=>onCompanySelection(company.akareNo.toString(),value)} 
                                disabled={user?.ID.toString() === userId}
                                />
                            <label htmlFor="switch" className="text-sm text-gray-500 ml-4">
                                {company.haldaName}
                            </label>
                        </div>
                    )
                }):
                <div>Inga företag hittade</div>
            }
        </div>
    );
};

export default AdminSection;