import React,{ useState , useEffect} from "react";
import { Title, Switch } from "@tremor/react";
import axios from 'axios';
import { Company } from "../../types/company";
import { User } from "../../types/user";

type EkonomiSectionProps = {
    companySelection: any,
    onCompanySelection: (id:string,value:boolean) => void,
    setCompanySelection: (selection: { [key: number]: boolean }) => void,
    userId: string | undefined,
    

}
const EkonomiSection = (props:EkonomiSectionProps) => {

    const {
        companySelection,
        onCompanySelection,
        setCompanySelection,
        userId,
    } = props

    const [companies, setCompanies] = useState<Company[] | null>();

    const getCompanies = async () => {
        const response = await axios.get('/company/all');
        if(response.data.length > 0){
            const allCompanies : Company[] = response.data;
            setCompanies(allCompanies);

            if(userId){
                const usercompaniesRes = await axios.get('/company/user?userId='+userId);
                if(usercompaniesRes.data.length > 0){
                    let temp:{[key:string]:boolean} = {};
                    allCompanies.forEach((company) => {
                        usercompaniesRes.data.includes(company.id) ? temp[company.id] = true : temp[company.id] = false;
                    })
                    setCompanySelection(temp);
                }
            }else{
                let temp:{[key:string]:boolean} = {};
                response.data.forEach((company:Company) => {
                    temp[company.id] = false;
                })
                setCompanySelection(temp);
            }
        }   
    }

    useEffect(() => {
        getCompanies();
    },[])

    return (
        <div className="mt-6 mb-4">
            <Title className="mb-4">Företag</Title>
            { companies ?
                companies.map((company,idx) => {
                    return (
                        <div key={idx} className="flex flex-row items-center mt-2">
                            <Switch 
                                id="switch" 
                                name={company.id.toString()} 
                                checked={companySelection[company.id]} 
                                onChange={(value)=>onCompanySelection(company.id.toString(),value)} 
                                />
                            <label htmlFor="switch" className="text-sm text-gray-500 ml-4">
                                {company.name}
                            </label>
                        </div>
                    )
                }):
                <div>Inga företag hittade</div>
            }
        </div>
    );
};

export default EkonomiSection;