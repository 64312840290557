import React, {useState, useEffect} from "react";
import axios from "axios";
import {useAuth} from "../auth/AuthContext";
import {useNavigate} from "react-router-dom";
import RegisterHeader  from "../components/Money/RegisterHeader";
import RegisterHistory from "../components/Money/RegisterHistory";
import RegisterMoney from "../components/Money/RegisterMoney";
import {Card,Text} from "@tremor/react";
import dayjs from "dayjs";
import { Company } from "../types/company";
import { Driver } from "../types/driver";
import { RegisterMoney as RegisterMoneyType } from "../types/RegisterMoney";

const Register = () => {
    const {isAuthenticated,user} = useAuth()
    const navigate = useNavigate()

    const [companyAkareNo, setCompanyAkareNo] = useState<number | null>(null);
    const [driverNo, setDriverNo] = useState<number | null>(null);
    const [date, setDate] = useState(dayjs().set('date',1).format('YYYY-MM-DD'));
    const [companies, setCompanies] = useState<Company[] |null>(null);
    const [drivers, setDrivers] = useState<Driver[] |null>(null);
    const [amount, setAmount] = useState(0);
    const [moneyHistory, setMoneyHistory] = useState<RegisterMoneyType[] | null>(null);
    const [missingMoney, setMissingMoney] = useState<number | 0>(0);

    useEffect(() => {
        async function getCompanies(){
            const response = await axios.get('/company/available');
            setCompanies(response.data);
        }
        if(isAuthenticated){
            getCompanies();
        }
    },[]);

    useEffect(() => {
        if(!user || !isAuthenticated){
            navigate('/signin')
        }
    }   , [isAuthenticated,navigate,user])

    useEffect(() => {
        async function getDrivers(){
            const response = await axios.get('/drivers?akareNo='+companyAkareNo+'&startdate='+date);
            setDrivers(response.data);
        }
        if(companyAkareNo && date){
            setDriverNo(null)
            getDrivers();
            setMoneyHistory(null);
            getMissingMoney()
        }
    },[companyAkareNo,date]);

    useEffect(() => {
        getDriverData();
        getMissingMoney();
    },[driverNo]);

    async function getDriverData(){
        if(!driverNo)
            return;
        try{
            const response = await axios.get(`/money?driverNo=${driverNo}&akareNo=${companyAkareNo}&startdate=${date}`);
            if(response.data){
                setMoneyHistory(response.data);
            }
        }catch(err){
            console.log(err);
            setMoneyHistory(null);    
        }
        return;
    }

    async function getMissingMoney(){
        if(!driverNo)
            return;
        try{
            const response = await axios.get(`/money/missing?driverNo=${driverNo}&akareNo=${companyAkareNo}&startdate=${date}`);
            if(response.data){
                setMissingMoney(response.data['Sum']);
            }
        }catch(err){
            console.log(err);
            setMissingMoney(0);    
        }
        return;
    }

    const deleteMoney = async (id:number) => {
        try{
            const response = await axios.delete(`/money/${id}`);
            if(response.status === 200){
                getDriverData();
            }
        }catch (err){
            console.log(err);
        }
    }

    const registerMoney = async () => {
        if(!companyAkareNo || !driverNo || !date || !amount)
            return;
        try{
            const response = await axios.post('/money',{
                akareNo: companyAkareNo,
                driverNo: driverNo,
                date: date,
                amount: amount
            });
            if(response.status === 200){
                setAmount(0);
                getDriverData();
            }
        }catch (err){
            console.log(err);
        }
    }

    const disabled = ():boolean => {
        if(!companyAkareNo || !driverNo || !date || !amount)
            return true;
        return false;
    }

    if(!isAuthenticated){
        return null;
    }

    return (
        <div className="container mx-auto mt-8 w-[80%]">
            <Card>
                <RegisterHeader 
                    companyAkareNo={companyAkareNo}  
                    setCompanyAkareNo={setCompanyAkareNo}
                    driverNo={driverNo}
                    setDriverNo={setDriverNo}
                    date={date}
                    setDate={setDate}
                    companies={companies}
                    drivers={drivers}
                />
                <Text className={`mt-2 ${(missingMoney >= 0 ) ? 'text-green-500' : 'text-red-500' }`}>Kvar att redovisa: {missingMoney ? missingMoney : 0} kr</Text>
                <RegisterMoney 
                    amount={amount}
                    setAmount={setAmount}
                    registerMoney={registerMoney}
                    disabled={disabled}
                    />
                <RegisterHistory 
                    moneyHistory={moneyHistory} 
                    driverName={(drivers && driverNo) ? drivers.find(d => d.driverNo === driverNo)?.name || '' : '' } 
                    deleteMoney={deleteMoney} />
            </Card>
        </div>
    );
}

export default Register;