import React from 'react';
import {Dialog, DialogPanel} from '@tremor/react';
import NewShift from '../Shift/NewShift';

type CustomShiftOverlayProps = {
    customShiftDriverNo: number
    closeNewShift: () => void
}

const CustomShiftOverlay = (props:CustomShiftOverlayProps) => {  
    
const {
    customShiftDriverNo,
    closeNewShift
} = props

    return (
        <Dialog open={true} onClose={(val) => closeNewShift()} static={true}>
            <DialogPanel className="max-w-5xl">
                <div >
                    <NewShift customShiftDriverNo={customShiftDriverNo} closeNewShift={closeNewShift}/>
                </div>
            </DialogPanel>
      </Dialog>
    )
}

export default CustomShiftOverlay;

