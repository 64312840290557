import React from "react";
import {Text,DatePicker,SearchSelect, SearchSelectItem ,Title, DatePickerValue} from "@tremor/react";

import dayjs from "dayjs";
import { Company } from "../../types/company";
import { Driver } from "../../types/driver";

type RegisterHeaderProps = {
    companyAkareNo:number | null,
    setCompanyAkareNo: (companyAkareNo:number) => void,
    driverNo: number | null,
    setDriverNo: (driverNo:number) => void,
    date: string,
    setDate: (date:string) => void,
    companies: Company[]|null,
    drivers: Driver[] | null
}

const RegisterHeader = (props:RegisterHeaderProps) => {

    const {
        companyAkareNo,
        setCompanyAkareNo,
        driverNo,
        setDriverNo,
        date,
        setDate,
        companies,
        drivers
    } = props
    
    const onChangeDate = (value:DatePickerValue) => {
        let date = dayjs(value);
        date = date.date(1);
        let newDate = ""
        newDate = date.format('YYYY-MM-DD');

        setDate(newDate)
    }

    return (
        <>
            <Title className="">Registera kontant</Title>
            <div className="flex flex-row ">
                <div className="mt-4 mb-2  mr-6">
                    <Text className="mb-2">Företag</Text>
                    <SearchSelect placeholder="Sök" value={companyAkareNo ? companyAkareNo.toString() : ""} onValueChange={(value)=>setCompanyAkareNo(Number(value))}>
                        {companies && 
                            companies.map((company,idx) => (
                                <SearchSelectItem key={idx} value={company.akareNo.toString()}>
                                    {company.haldaName}
                                </SearchSelectItem>
                            ))
                        }
                    </SearchSelect>
                </div>
                <div className="mt-4 mb-2  mr-6">
                    <Text className="mb-2">Månad</Text>
                    <DatePicker value={(!date) ? new Date() : new Date(date) } displayFormat="yyyy-MM-dd" className="max-w-sm mx-auto" onValueChange={onChangeDate}/>
                </div>
                <div className="mt-4 mb-2  mr-6">
                    <Text className="mb-2">Förare</Text>
                    <SearchSelect placeholder="Välj" value={driverNo?.toString()} onValueChange={(value)=>setDriverNo(Number(value))}>
                        {drivers && 
                            drivers.map((driver,idx) => (
                                <SearchSelectItem key={idx} value={driver.driverNo!.toString()}>
                                    {driver.name} - {driver.driverNo}
                                </SearchSelectItem>
                            ))
                        }
                    </SearchSelect>
                </div>
               
            </div>
       </>
    );
}

export default RegisterHeader;