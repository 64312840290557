import React from 'react';
import {TableBody, TableCell, TableHead, TableHeaderCell, Title, Table,TableRow, Text} from "@tremor/react";
import {convertToCurrency} from "../../lib/utils";
import {TrashIcon} from '@heroicons/react/24/outline';
import { RegisterMoney } from '../../types/RegisterMoney';
;


type RegisterHistoryProps = {
    moneyHistory: RegisterMoney[] | null,
    driverName: string,
    deleteMoney: (id:number) => void
}

const RegisterHistory = (props:RegisterHistoryProps) => {

    const {
        moneyHistory,
        driverName,
        deleteMoney
    } = props

    if(!moneyHistory)
        return null

    if(moneyHistory.length === 0)
        return <Text>Ingen Registrering</Text>

    return (
        <div className="mt-6">
            <Title>Registrering för {driverName}</Title>
            <Table className="mt-6 ">
                <TableHead>
                    <TableHeaderCell className="text-left">Admin Namn</TableHeaderCell>
                    <TableHeaderCell className="text-center">Företag</TableHeaderCell>
                    <TableHeaderCell className="text-center">Kontant</TableHeaderCell>
                    <TableHeaderCell className="text-center">Månad</TableHeaderCell>
                    <TableHeaderCell className="text-center">Lagt till</TableHeaderCell>
                </TableHead>
                <TableBody>
                    {
                        moneyHistory.map((m,idx) =>(
                            <TableRow key={idx}>
                                <TableCell className="text-left">{m.name}</TableCell>
                                <TableCell className="text-center">{m.haldaName}</TableCell>
                                <TableCell className="text-center">{convertToCurrency(m.cash)}</TableCell>
                                <TableCell className="text-center">{new Date(m.month).toISOString().split("T")[0]}</TableCell>
                                <TableCell className="text-center">{new Date(m.added).toISOString().split("T")[0]}</TableCell>
                                <TableCell className="text-center">
                                    <TrashIcon className="w-4 h-4 text-red-300 cursor-pointer hover:text-red-600" onClick={() => deleteMoney(m.id)}/>
                                </TableCell>
                            </TableRow>
                        )) 
                    }
                </TableBody>
            </Table>
        </div>
    );
}

export default RegisterHistory;
