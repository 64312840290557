import { Report, Shift } from "../types/shifts"

const dayjs = require('dayjs')
var utc = require('dayjs/plugin/utc')
var duration = require('dayjs/plugin/duration')
var timezone = require('dayjs/plugin/timezone')
dayjs.extend(duration)
dayjs.extend(utc)
dayjs.extend(timezone)
var customParseFormat = require('dayjs/plugin/customParseFormat')
dayjs.extend(customParseFormat)

export const getFractionalHour = function (time: string): number {

  // Check if time is a string and contains the ":" character
  if (typeof time !== 'string' || !time.includes(':')) {
    console.error('Invalid time format:', time);
    return 0;
  }

  let parts = time.split(':');
  let minutes = Math.round(parseInt(parts[1]) / 60 * 100) / 100;
  let newTime = parseFloat(parts[0]) + minutes;
  const rounded = Math.round((newTime + Number.EPSILON) * 100) / 100
  return rounded;
}

export const parseDecimalToToTime = function (decimal: number): string {
  const hours = Math.floor(decimal);
  const minutes = Math.round((decimal - hours) * 60);
  const time = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
  return time;
}

export const convertToCurrency = function (value: number): string {
  const currency = new Intl.NumberFormat('en-SE', { style: 'currency', currency: 'SEK' }).format(value);
  return currency;
}

export const minutesToTimeStamp = function (minutes: number): string {
  const hours = Math.floor(minutes / 60);
  const mins = minutes % 60;

  return `${String(hours).padStart(2, "0")}:${String(mins).padStart(2, "0")}`;
};

export const formatDate = function (date: string): string {
  if (!date) return date;
  // Remove .000Z and remove T
  return date.split("T")[0].replace('.000Z', '');
}

export const getStartDate = function (startdate: Date): string {
  // First day of the month
  const date = new Date(startdate);
  date.setDate(1);
  return date.toISOString().split('T')[0];
}

export const formatTime = function (time: string): string {
  if (!time) return time;
  if (time.includes("T")) {
    const retTime = dayjs(time).tz("UTC").format("HH:mm")
    return retTime;
  }
  return time;
}

export const calculateTotalHours = function (shift: Shift) {
  if (!shift?.report) return 0;

  if (!shift.report.enddate || !shift.report.startdate || 
      !shift.report.endtimeUI || !shift.report.starttimeUI) {
    return 0;
  }

  // Normalize the time format
  let starttimeUI = shift.report.starttimeUI;
  let endtimeUI = shift.report.endtimeUI;

  // If the time includes a full timestamp, extract just the time portion
  if (starttimeUI.includes('T')) {
    starttimeUI = dayjs(starttimeUI).format('HH:mm');
  }
  if (endtimeUI.includes('T')) {
    endtimeUI = dayjs(endtimeUI).format('HH:mm');
  }
  
  // Create full datetime strings using the respective dates
  const startDateTime = dayjs(`${shift.report.startdate}T${starttimeUI}`);
  const endDateTime = dayjs(`${shift.report.enddate}T${endtimeUI}`);

  const duration = dayjs.duration(endDateTime.diff(startDateTime));
  const totalDuration = duration.asMinutes();
  // Breaks Duration
  let breakDuration = 0;

  if (shift && shift.breaks) {
    shift.breaks.forEach(breakItem => {
      if (!breakItem.starttime || !breakItem.endtime) return;
      //Figure out if time has a date stamp
      let breakStart = breakItem.starttime;
      let breakEnd = breakItem.endtime;

      if (!breakStart.includes("T") || !breakEnd.includes("T")) {
        breakStart = dayjs(shift.report.startdate + "T" + breakItem.starttime);
        // have to check if it is the next day
        breakEnd = dayjs(shift.report.startdate + "T" + breakItem.endtime);
      } else {
        breakStart = dayjs(breakItem.starttime);
        breakEnd = dayjs(breakItem.endtime);
      }

      const start = dayjs(breakStart);
      const end = dayjs(breakEnd)
      const duration = dayjs.duration(end.diff(start));
      breakDuration += duration.asMinutes();
    });
  }

  const finalMinutes = totalDuration - breakDuration;
  if (isNaN(finalMinutes)) return "Check values: dates and times";
  
  // Convert total minutes to hours and minutes
  const hours = Math.floor(finalMinutes / 60);
  const minutes = Math.floor(finalMinutes % 60);
  
  // Format with padStart to ensure two digits
  const displayDuration = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
  return displayDuration;
}

export const getTotalDrivenPercentages = (statsData: any) => {
  const result = [
    { name: "Paid Dist", value: statsData?.drivenTotal.dist_paid / 1000 },
    { name: "Unpaid Dist", value: (statsData?.drivenTotal.dist_taxi - statsData?.drivenTotal.dist_paid) / 1000 }
  ]

  return result;
};

export const getTotalHoursWorked = (statsData: any) => {
  const { totalWorked, totalBreak } = statsData?.totalHours;

  const result = [
    { name: "worked", value: totalWorked },
    { name: "break", value: totalBreak },
  ]

  return result;
};

// Not in use?

export const validateTime = (value: string) => {
  if (!value) return true;

  const timeRegex = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/;
  const valid = timeRegex.test(value);
  return valid;
}

export const getFormattedDate = (date: string) => {
  const dateObj = new Date(date);
  const month = dateObj.getMonth() + 1;
  const day = String(dateObj.getDate()).padStart(2, '0');
  const year = dateObj.getFullYear();
  return `${year}-${month}-${day}`;
}


export const getReportType = (type: number): string => {
  const types = {
    1: 'Manuell',
    2: 'Semester',
    3: 'Sjuk',
    4: 'Övrigt',
  } as { [key: number]: string }
  return types[type] || 'Okänd';
}

export const holidays = [
  "01-01-2024",
  "06-01-2024",
  "29-03-2024",
  "30-03-2024",
  "31-03-2024",
  "01-04-2024",
  "01-05-2024",
  "09-05-2024",
  "06-06-2024",
  "21-06-2024",
  "22-06-2024",
  "24-12-2024",
  "25-12-2024",
  "26-12-2024",
  "31-12-2024",
  "01-01-2025",
  "06-01-2025",
  "18-04-2025",
  "19-04-2025",
  "20-04-2025",
  "21-04-2025",
  "01-05-2025",
  "29-05-2025",
  "06-06-2025",
  "20-06-2025",
  "21-06-2025",
  "24-12-2025",
  "25-12-2025",
  "26-12-2025",
  "31-12-2025",
  "01-01-2026",
  "26-01-2026",
  "03-04-2026",
  "04-04-2026",
  "05-04-2026",
  "06-04-2026",
  "01-05-2026",
  "14-05-2026",
  "06-06-2026",
  "19-06-2026",
  "20-06-2026",
  "24-12-2026",
  "25-12-2026",
  "26-12-2026",
  "31-12-2026",
];