import { Title , Switch , Text, Col, Grid, Divider} from '@tremor/react';
import { useNavigate } from 'react-router-dom';
import React, { useEffect , useState} from 'react'
import axios from 'axios';
import DashboardHeader from '../components/Dashboard/DashboardHeader';
import { useAuth } from '../auth/AuthContext'
import dayjs from 'dayjs';
import UserView from '../components/Dashboard/UserView';
import OverAllView from '../components/Dashboard/OverallView';
import PulseLoader from "react-spinners/PulseLoader";  
import { Driver } from '../types/driver';
import { Company } from '../types/company';
import { Vechile } from '../types/vechiles';
import { VehicleStats, VehicleStatsOverall } from '../../../shared/types/vechileStats';
var duration = require('dayjs/plugin/duration')
dayjs.extend(duration)


const Dashboard = () => {
    const {isAuthenticated,user} = useAuth()
    const navigate = useNavigate()

    const [statsData,setStatsData] = useState<VehicleStats | VehicleStatsOverall | null>(null);
    const [selected,setSelected] = useState<number | null>(null);
    const [isOverall, setIsOverall] = useState(false);
    
    const [companyNo, setCompanyNo] = useState<number | null>(null);
    const [companies, setCompanies] = useState<Company[] | null >(null);
    
    const [drivers, setDrivers] = useState<Driver[] | null>(null);
    const [driverNo, setDriverNo] = useState<number | null>(null);

    const [carNo, setCarNo] = useState<number | null>(null);
    const [cars, setCars] = useState<Vechile[] | null >(null);

    const [moms, setMoms] = useState(true);

    const [startdate, setStartDate] = useState(dayjs().format('YYYY-MM-DD'));
    const [enddate, setEndDate] = useState(dayjs().format('YYYY-MM-DD'));

    const [fetchingData, setFetchingData] = useState(false);

    
    useEffect(() => {
        if(!user || !isAuthenticated){
            console.log("Not authenticated")
            navigate('/signin')
            return;
        }

        if(user && user?.rights === 5)
            setDriverNo(user.driverNo);

    }, [isAuthenticated,navigate,user])


    async function getCompanies(){
        if(!startdate || !enddate)
            return;

        const response = await axios.get(`/company/available?startdate=${startdate}&enddate=${enddate}`); 
        setCompanies(response.data);
    }


    useEffect(() => {
        getCompanies();
        if((companyNo && startdate && enddate && driverNo) || (user?.rights === 2 && startdate && enddate && isOverall && companyNo)){
            getStats();
        }
    },[startdate,enddate]);


    useEffect(() => {
        async function getDrivers(){
            const response = await axios.get(`/drivers?akareNo=${companyNo}&startdate=${startdate}&enddate=${enddate}`);
            setDrivers(response.data);
        }

        async function getCars(){
            const response = await axios.get(`/cars?akareNo=${companyNo}&startdate=${startdate}&enddate=${enddate}`);
            setCars(response.data);
        }

        if(companyNo && startdate && enddate && (user && (user.rights === 2 || user.rights === 1 )) && !isOverall){
            getDrivers();
            getCars();
        }
    },[companyNo,startdate,enddate]);


    useEffect(() => {   
        if(companyNo && (driverNo || carNo) && startdate && enddate)
            getStats();
        if(companyNo && startdate && enddate && (user && (user.rights === 2 || user.rights === 1) ) && isOverall)
            getStats()
    },[driverNo,companyNo,startdate,enddate,isOverall,carNo]);


    const getStats = async () => {
    try{
        setFetchingData(true);
        const optionalParams = `${ (driverNo) ? '&driverNo='+driverNo : '' }${ (carNo) ? '&carNo='+carNo : ''}`
        const response = await axios.get(`/dashboard/stats?akareNo=${companyNo}&startdate=${startdate}&enddate=${enddate}&overall=${isOverall}${optionalParams}`);
    
        if(response.data.length !== 0){
            if(isOverall){
                const statsData: VehicleStatsOverall = response.data;
                setStatsData(statsData);
            }
            else {
                const statsData: VehicleStats = response.data;
                setStatsData(statsData);
            }
            
            console.log("statsData",statsData);
        }

    }catch(err){
        console.log(err);
    }
        setFetchingData(false);
    }

    const toggleOverallSwitch = (value:boolean) => {
        if(!value){
            setStatsData(null);
        }
        setDriverNo(null);
        setCarNo(null);
        setSelected(null);
        setIsOverall(value)
    }

    if(!isAuthenticated){
        return null;
    }
console.log(moms)
    return (
        <div className="mx-auto max-w-7xl px-4 lg:px-8">
            <Title className="mt-6">Dashboard</Title>
            <DashboardHeader 
                setCompanyNo={setCompanyNo}
                setDriverNo={setDriverNo}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                companyNo={companyNo}
                companies={companies}
                driverNo={driverNo}
                drivers={drivers}
                startDate={startdate}
                endDate={enddate}
                overall={isOverall}
                setOverall={setIsOverall}
                carNo={carNo}
                cars={cars}
                setCarNo={setCarNo}
                setSelected={setSelected}
            />
            <Divider>Options</Divider>
            <Grid numItems={6}>
                <Col numColSpan={3} numColSpanSm={6} numColSpanMd={3} >
                    {
                        (user && (user.rights === 2 || user.rights === 1 )) ? (
                        <>
                            <div className="flex flex-row items-center mt-4">
                                <Switch name="switch_overall" className="mr-2 mt-2" onChange={(value) => {toggleOverallSwitch(value)}} checked={isOverall} />
                                <label htmlFor="switch_overall" className="text-sm text-gray-500 ">
                                Företagsöversikt
                                </label>
                            </div>
                            <p className="text-tremor-label text-tremor-content dark:text-dark-tremor-content">
                             Företagsöversikt visar företagets övergripande statistik
                            </p>
                        </>
                        ) : null
                    }
                </Col>
                <Col numColSpan={3} numColSpanSm={6} numColSpanMd={3}>
                    <>
                        <div className="flex flex-row items-center mt-4">
                            <Switch name="switch_overall" className="mr-2 mt-2" onChange={(value) => {setMoms(value)}} checked={moms} />
                            <label htmlFor="switch_overall" className="text-sm text-gray-500 ">
                            Moms
                            </label>
                        </div>
                        <p className="text-tremor-label text-tremor-content dark:text-dark-tremor-content mb-4">
                        Visa värden exkl moms
                        </p>
                    </>
                </Col>
            </Grid>
            
            {
                fetchingData ? <PulseLoader color="#36d7b7"  size={5} /> :
                (isOverall) ? <OverAllView statsData={statsData as VehicleStatsOverall} moms={moms} /> : <UserView statsData={statsData as VehicleStats} driverNo={driverNo} carNo={carNo} moms={moms}/>
            }
          
        </div>
    )
}

export default Dashboard;
