import React from "react";
import { DonutChart, Card, Title , Col} from "@tremor/react";
import { Sign } from "crypto";

type DoughnutChartProps = {
        index:string,
    title:string,
    category: string,
    valueFormatter?: (number:number) => string;
    data:{name:string,value:number}[]
    
}

const DoughnutChart = (props:DoughnutChartProps) => {


    const {
        index,
        title,
        category,
        valueFormatter,
        data
    } = props

    return (
        <Col numColSpanLg={1} numColSpan={2} className="m-4">
            <Card className="">
            <Title>{title}</Title>
            <DonutChart
                className="mt-6"
                data={data}
                category={category}
                index={index}
                valueFormatter={valueFormatter}
                colors={["green", "rose", "indigo", "rose", "cyan", "amber"]}
            />
            </Card>
        </Col>
    );
}

export default DoughnutChart;