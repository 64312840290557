import * as React from 'react';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { APP_ROUTES } from '../config/routes';
import { UserOrDriver } from '../types/driver';

type AuthContextTypes = {
  isAuthenticated: boolean,
  user: UserOrDriver | null,
  signIn: (user:UserOrDriver) => void,
  signOut: () => void
}

const AuthContext = React.createContext<AuthContextTypes>({
  isAuthenticated: false,
  user: null,
  signIn: (user:UserOrDriver) => {},
  signOut: () => {}
});

type AuthProviderProps = {
  children :React.ReactNode
}

function AuthProvider( props:AuthProviderProps) {

  const {
    children
  } = props

  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [waitAuthCheck, setWaitAuthCheck] = useState<boolean>(true);
  const [user, setUser] = useState<UserOrDriver | null>(null);
  const navigate = useNavigate()

  useEffect(() => {
    if (!user || !isAuthenticated) {
      navigate('/signin')
      return;
    }

  }, [isAuthenticated, navigate, user])

  const signOut = async () => {
    try {
      await axios.get('/signout');
      setIsAuthenticated(false);
      setUser(null);
      setWaitAuthCheck(false);
      navigate('/signin')
    } catch (e) {
      console.log(e)
      //Not possible, show error
    }
  }


  const signIn = (user: UserOrDriver) => {
    setUser(user);
    setIsAuthenticated(true);
    setWaitAuthCheck(false);
    navigate(APP_ROUTES.SHIFTS)
  }



  useEffect(() => {
    async function handleAuthentication() {
      try {
        const response = await axios.get('users/me');
        if (response.status === 200) {
          signIn({ ...response.data.data });
        }
      } catch (e) {
        console.log(e)
        signOut();
      }
    };
    handleAuthentication();
  }, []);



  return waitAuthCheck ? (

    <h5>Loading..888..</h5>
  ) : (
    <AuthContext.Provider value= {{ isAuthenticated, user, signIn, signOut }
}> { children } </AuthContext.Provider>
  );
}

function useAuth() {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthProvider');
  }
  return context;
}

export { AuthProvider, useAuth };
