import React from "react";
import {
    TableCell,
    TableRow,
    Text,
    Badge, 
    Button
} from "@tremor/react";

import {convertToCurrency} from "../../lib/utils";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline";
type SalaryTableRowProps = {
    rowData:{
        label: string,
        value: number | string,
        alternateValue: null | number,
        type?: string,
        approved?: number | string
    },
    badge?: number | null,
    style?: boolean
    trigger?: () => void,
    seeDetails?: boolean

}

const SalaryTableRow = (props:SalaryTableRowProps) => {

    const {
        rowData,
        badge,
        style,
        trigger,
        seeDetails
    } = props


    return (
        <TableRow className={style ? "bg-slate-100" : ""}>
        <TableCell className="w-[60%]">
          <Text>
            {rowData["label"]}{" "}
            {trigger ? (
              <Button
                icon={seeDetails ? ChevronUpIcon : ChevronDownIcon}
                iconPosition="right"
                variant="light"
                className="text-xs ml-4"
                size="xs"
                onClick={() => trigger()}
              >
                Detaljer
              </Button>
            ) : null}{" "}
          </Text>
        </TableCell>
        <TableCell className="flex flex-row">
          <Text className={`${Number(rowData["value"]) < 0 ? "text-red-400" : ""} mr-2`}>
            {rowData["type"] === "currency"
              ? convertToCurrency(Number(rowData["value"]))
              : rowData["value"]}
          </Text>
          {badge ? (
            <Badge size="xs" color="red">
              {badge}
            </Badge>
          ) : null}
        </TableCell>
        <TableCell>
          <Text>{rowData["alternateValue"]}</Text>
        </TableCell>
        {/* <TableCell>
            <Text className="text-right">{(rowData['type'] === "currency") ? convertToCurrency( rowData['approved'] ) : rowData['approved'] }</Text>
        </TableCell> */}
      </TableRow>
    );
  };

export default SalaryTableRow;