import React,{ useEffect, useState } from "react";
import { Text, TextInput, Switch, Title,} from "@tremor/react";
import axios from "axios";
import { User } from "../../types/user";
import { Company } from "../../types/company";

type VaxelPersonalSection = {
    onSectionChange: (value:string,name:string) => void,
    companySelection: any,
    onCompanySelection: (id:string,value:boolean) => void,
    setCompanySelection: (selection: { [key: number]: boolean }) => void,
    selectedUser: User,
    userId: string | null
}

const VaxelPersonalSection = (props:VaxelPersonalSection) => {


    const { 
        onSectionChange,
         companySelection, 
         onCompanySelection, 
         setCompanySelection, 
         selectedUser, 
         userId 
       } = props;

    const [driverNumber, setDriverNumber] = useState<number>(selectedUser.driverNo!);
    const [companies, setCompanies] = useState<Company[] | null >(null);

    const getCompanies = async () => {
        const response = await axios.get('/company/all');
        if(response.data.length > 0){
            const allCompanies:Company[] = response.data;
            setCompanies(allCompanies);

            if(userId){
                const usercompaniesRes = await axios.get('/company/user?userId='+userId);
                if(usercompaniesRes.data.length > 0){
                    let temp:{[key:string]:boolean} = {};
                    allCompanies.forEach((company) => {
                        usercompaniesRes.data.includes(company.id) ? temp[company.id] = true : temp[company.id] = false;
                    })
                    setCompanySelection(temp);
                }
            }else{
                let temp:{[key:string]:boolean} = {};
                response.data.forEach((company:Company) => {
                    temp[company.id] = false;
                })
                setCompanySelection(temp);
            }
        }   
    }

    useEffect(() => {
        getCompanies();
    },[])

    useEffect(() => {
        onSectionChange(("777"+driverNumber),'driverNumber');
    },[driverNumber]);

    return (
        <div>
            <div className="mt-4 mb-2">
                <Text className="mb-2">Anställningsnummer</Text>
                <TextInput name="text" onChange={(e)=>setDriverNumber(parseInt(e.target.value))} placeholder="101101101" defaultValue={driverNumber.toString()} type="text" />
            </div>
            <div className="mt-6 mb-4">
            <Title className="mb-4">Företag</Title>
            { companies ?
                companies.map((company,idx) => {
                    return (
                        <div key={idx} className="flex flex-row items-center mt-2">
                            <Switch 
                                id="switch" 
                                name={company.akareNo.toString()} 
                                checked={companySelection[company.akareNo]} 
                                onChange={(value)=>onCompanySelection(company.akareNo.toString(),value)} 
                                disabled={selectedUser?.ID === Number(userId)}
                                />
                            <label htmlFor="switch" className="text-sm text-gray-500 ml-4">
                                {company.haldaName}
                            </label>
                        </div>
                    )
                }):
                <div>Inga företag hittade</div>
            }
        </div>
        </div>
    );
};

export default VaxelPersonalSection;