import React from "react";
import { Card, Button, Text, Title } from "@tremor/react";
import { useAuth } from "../../auth/AuthContext";
import PulseLoader from "react-spinners/PulseLoader";
import { LockClosedIcon, PlusIcon } from "@heroicons/react/24/outline";
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import SalaryPassCard from "./SalaryPassCard";
import 'dayjs/locale/sv';
import { holidays } from "../../lib/utils";
import { Shift } from "../../types/shifts";
import { Month, FetchedShift } from "../../../../shared/types/shifts";

dayjs.extend(customParseFormat);
dayjs.extend(localizedFormat);
dayjs.locale('sv');

type SalaryDetailsProps = {
  data: Month[][],
  onToggle: (id: string) => void,
  lockPasses: () => void,
  inspectShift: (id: string) => void,
  closeInspectShift: () => void,
  selectedShiftId: string | null,
  isLockingPasses: boolean,
  addShift: (shift: Shift) => void,
  driverNo: number | null,
  addCustomShift: (driverNo: number) => void
}

const SalaryDetails = (props: SalaryDetailsProps) => {
  const {
    data,
    onToggle,
    lockPasses,
    inspectShift,
    driverNo,
    addCustomShift,
    isLockingPasses,
  } = props;

  const { user } = useAuth();

  if (!data) return null;

  const findFirstValidDate = (data: Month[][]) => {
    for (let i = 0; i < data.length; i++) {
      if (data[i][0]?.datestring) {
        return data[i][0].datestring;
      }
    }
    return null;
  }

  const firstValidDateString = findFirstValidDate(data);

  if (!firstValidDateString) {
    return <div>Inget giltigt datum hittades i data</div>;
  }

  const firstItemDate = dayjs(firstValidDateString, "DD-MM-YYYY");
  const month = firstItemDate.month();
  const year = firstItemDate.year();

  // Generate all dates in the month using dayjs
  const startOfMonth = dayjs().year(year).month(month).startOf('month');
  const endOfMonth = dayjs().year(year).month(month).endOf('month');

  const allDates = [];
  for (let date = startOfMonth; date.isBefore(endOfMonth) || date.isSame(endOfMonth); date = date.add(1, 'day')) {
    allDates.push(date);
  }

  return (
    <div className="flex flex-col pb-12">
      <Title className="">Redovisade pass</Title>
      {user && (user.rights === 2 || user.rights === 1) ? (
        <div className="flex flex-row justify-between items-center">
          <div className="mt-4 mb-4">
            <Button
              icon={LockClosedIcon}
              size="xs"
              onClick={() => lockPasses()}
            >
              {isLockingPasses ? (
                <PulseLoader color="#36d7b7" size={3} />
              ) : (
                "Lås alla pass"
              )}
            </Button>
          </div>
          <div className="mt-4 mb-4">
            <Button
              icon={PlusIcon}
              color={"green"}
              size="xs"
              onClick={() => driverNo && addCustomShift(driverNo)}
            >
              {isLockingPasses ? (
                <PulseLoader color="#36d7b7" size={3} />
              ) : (
                "Lägg till pass"
              )}
            </Button>
          </div>
        </div>
      ) : null}

      <div className="mx-auto w-full">
        {allDates.map((date) => {
          const dateString = date.format("YYYY-MM-DD");
          const weekday = date.format("dddd");
          const dayData = data.find(day => 
            day[0]?.datestring === date.format("DD-MM-YYYY")
          )?.[0];
          const shifts = dayData?.shifts || [];
          const isWeekend = weekday === "lördag" || weekday === "söndag";
          const holidayDateFormat = date.format("DD-MM-YYYY");
          const isHoliday = holidays.includes(holidayDateFormat);
          const bgColorClass = (Number(date.format("DD")) % 2 === 0) ? 'bg-slate-100' : 'bg-white';

          return (
            <Card key={dateString} className={`my-3 pb-1 tremor-small pt-4 ${bgColorClass}`}>
              <div className="text-sm font-medium text-tremor-content-strong dark:text-dark-tremor-content-strong mb-0">
                <Text
                  className={
                    "font-bold capitalize " +
                    (isWeekend || isHoliday
                      ? "text-rose-400"
                      : "text-slate-500")
                  }
                >
                  {dateString}
                  <span className="pl-3">
                    {weekday.charAt(0).toUpperCase() + weekday.slice(1)}
                  </span>
                </Text>
              </div>
              <div className={`leading-6 pb-5 pt-4 ${shifts.length === 0 ? 'sm:pb-2.5 sm:pt-0' : ''}`}>
                {shifts.map((shift, index) => (
                  <SalaryPassCard
                    key={index}
                    dayData={dayData}
                    item={shift}
                    user={user}
                    onToggle={onToggle}
                    inspectShift={inspectShift}
                  />
                ))}
              </div>
            </Card>
          );
        })}
      </div>
    </div>
  );
};

export default SalaryDetails;
