import React,{useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../auth/AuthContext';
import {Button,Select,SelectItem,Text,DatePicker, Col, Grid, DatePickerValue} from "@tremor/react";
import axios from 'axios';
import dayjs from 'dayjs';
import { Company } from '../types/company';

type ExportExcelProps = {
    type:string | null
}
const ExcelExport = (props:ExportExcelProps) => {
    const {
        type,
    } = props

    const navigate = useNavigate();
    const {isAuthenticated,user} = useAuth()
    const [akareNo, setAkareNo] = React.useState<number | null>(null);
    const [companies, setCompanies] = React.useState<Company[] | null>(null);
    const [processing, setProcessing] = React.useState(false);
    const [startdate, setStartDate] = React.useState(dayjs(new Date()).format('YYYY-MM-DD'));
    const [enddate, setEndDate] = React.useState(dayjs(new Date()).format('YYYY-MM-DD'));


    useEffect(() => {
        if(!user || !isAuthenticated){
            navigate('/signin')
        }
    }   , [isAuthenticated,navigate,user])

    useEffect(() => {
        async function getCompanies(){
            const response = await axios.get('/company/available');
            setCompanies(response.data);
        }
        getCompanies();
    },[]);


    const onChangeDate = (value:DatePickerValue,action:string) => {
        const date = dayjs(value).format('YYYY-MM-DD')
        console.log(date)
        if(action === 'startdate'){
            setStartDate(date)
        }else{
            setEndDate(date)
        }
    }

   

    const ExportExcel = async (type:string) => {

        

        try{
            if(!akareNo || processing)
                return;

            setProcessing(true);

            // Make the request with only CSV format
            const response = await axios.get(`/export/${akareNo}?type=${type}&startdate=${startdate}&enddate=${enddate}&format=csv`, {
                responseType: 'blob', // Handle the response as a binary file
            });

            if(response.data){
                const mimeType = 'text/csv';
                const url = window.URL.createObjectURL(new Blob([response.data], { type: mimeType }));
                const link = document.createElement('a');
                link.href = url;
                if( type === 'dump'){
                    link.setAttribute('download', `dump_${startdate}_${enddate}_${akareNo}.csv`);
                }else{
                    link.setAttribute('download', `${akareNo}-${type}.csv`);
                }

                document.body.appendChild(link);
                link.click();
                setProcessing(false);
            }
        }catch(err){
            console.log(err);
        }
    }

    if(companies && companies.length === 0)
        return <></>;

    return (
        <div className="container mx-auto flex flex-col justify-start items-center w-full h-screen">
            <Grid numItems={3} numItemsLg={12} numItemsMd={12} numItemsSm={12}>
                <Col numColSpan={12} numColSpanMd={6} numColSpanSm={12} numColSpanLg={4} >
                    <div className="mt-4 mb-2  mr-6">
                        <Text className="mb-2">Från:</Text>
                        <DatePicker value={ new Date(startdate) } displayFormat="yyyy-MM-dd" className=" mx-auto" onValueChange={(v)=>onChangeDate(v,'startdate')} />
                    </div>
                    <p className="mt-4 text-tremor-label text-tremor-content dark:text-dark-tremor-content">
                    Välj startdatum
                    </p>
                </Col>
                <Col numColSpan={12} numColSpanMd={6} numColSpanSm={12} numColSpanLg={4} >
                    <div className="mt-4 mb-2 mr-6">
                        <Text className="mb-2">Till:</Text>
                        <DatePicker value={ new Date(enddate) } displayFormat="yyyy-MM-dd" className=" mx-auto" onValueChange={(v)=>onChangeDate(v,'enddate')} />
                    </div>
                    <p className="mt-4 text-tremor-label text-tremor-content dark:text-dark-tremor-content">
                    Välj slutdatum
                    </p>
                </Col>
                <Col numColSpan={12} numColSpanMd={6} numColSpanSm={12} numColSpanLg={4} >
                    <div className="mt-4">
                        <Text className="mb-2">Företag:</Text>
                        <Select placeholder='Välj' value={akareNo ? akareNo.toString() : ""} onValueChange={(v)=>setAkareNo(Number(v))}>
                        { companies && companies.map((company,idx) => (
                                    <SelectItem key={idx}  value={company.akareNo.toString()}>
                                        {company.haldaName}
                                    </SelectItem>
                                ))
                                
                            }
                        </Select>
                    </div>
                    <p className="mt-4 text-tremor-label text-tremor-content dark:text-dark-tremor-content">
                    Välj företag
                    </p>
                </Col>
                <Col numColSpan={12} numColSpanMd={6} numColSpanSm={12} numColSpanLg={4}>
                    <div className="mt-11 flex flex-row ">
                        {/* <Button className="mr-4 p-2" size="xs" onClick={()=> ExportExcel('tidsdiffar')}>Tidsdiffar</Button>
                        <Button className="mr-4 p-2" size="xs" onClick={()=> ExportExcel('felanmalan')}>FelAnmälan</Button>
                        <Button className="mr-4 p-2" size="xs" onClick={()=> ExportExcel('bommar')}>Bommar</Button> */}
                        <Button className="ml-4 p-2" size="xs" onClick={()=> ExportExcel('dump')}>Dump</Button>
                    </div>
                    { (processing) ? <Text className="mt-4">Processing...</Text> : <></> } 
                </Col>
            </Grid>
        </div>
    );
}

export default ExcelExport;