import React from 'react'
import { Title, Button, Textarea ,Text } from '@tremor/react'
import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/24/outline'
import { useAuth } from '../../auth/AuthContext'
import { Approved, ApprovedSalary } from '../../types/salaryData'

type SalaryApproveProps = {
    approvedSalary : ApprovedSalary | null,
    approveSalary: () => void,
    setComment: (comment:string) => void,
    comment: string,
    setAdminComment: (adminComment:string) => void,
    adminComment: string,
    date: string
    
}

const SalaryApprove = (props:SalaryApproveProps) => {
    
    const {
        approvedSalary,
        approveSalary,
        setComment,
        comment,
        setAdminComment,
        adminComment,
        date
    } = props

    const {user} = useAuth();
    
    const isDisabled = () => {
        if(user && user.rights === 5){
            let sameMonth = false;
            // Check if date is the current month
            if(date){
                const currentDate = new Date();
                const currentMonth = currentDate.getMonth();
                const currentYear = currentDate.getFullYear();

                const salaryDate = new Date(date);
                const salaryMonth = salaryDate.getMonth();
                const salaryYear = salaryDate.getFullYear();
                
                if(currentMonth === salaryMonth && currentYear === salaryYear){
                    sameMonth = true;
                }
            }

            if(sameMonth){
                return true; // should be disalbed
            }else{
                return false;
            }

        }
    }

    const isApprovedBy = (approved:number):boolean => {
        if(approved === 1) return true;
        else return false
    }

    const getApproveButton = () => {
        if(approvedSalary && approvedSalary.completed_userID){
            return null;
        }
        if (user){

            if(user.rights === 5){
                return (
                    <Button 
                        disabled={isDisabled()} 
                        className={`mt-4 float-right ${isDisabled() ? 'opacity-50' : ''}`} 
                        onClick={() => approveSalary()}
                    >
                        Godkänn
                    </Button>
                )
            }else if((user.rights === 2 || user.rights === 1 ) && approvedSalary?.approvedByDriver){
                return (
                    <Button 
                        disabled={approvedSalary?.approvedByAdmin} 
                        className={`mt-4 float-right ${approvedSalary?.approvedByAdmin ? 'opacity-50' : ''}`}
                        onClick={() => approveSalary()}
                    >
                        Godkänn
                    </Button>
                )
            }else if(user.rights === 6){
                return (
                    <Button 
                        disabled={approvedSalary?.approvedByDriver || approvedSalary?.approvedByAdmin}
                        className={`mt-4 float-right ${(approvedSalary?.approvedByDriver || approvedSalary?.approvedByAdmin) ? 'opacity-50' : ''}`}
                        onClick={() => approveSalary()}
                    >
                        Godkänn
                    </Button>
                )
            }
        }
    }

    return (
        <div className="mt-6">
            <>
                <label>Förarens kommentar</label>
                {user && approvedSalary &&
                    ( user.rights === 5) ? 
                    <Textarea className="mt-2" onValueChange={setComment} defaultValue={comment} disabled={approvedSalary!.approvedByDriver}/>
                    : <Text className="mt-2">{(comment.length === 0 ) ? 'Ingen kommentar' : comment }</Text> 
                }
                
            </>
            {user && approvedSalary &&
                (user.rights !== 5 && approvedSalary.approvedByDriver) ? (
                    <div className="mt-6">
                    <label>Admin kommentar</label>
                    {
                        ( (user.rights === 2 || user.rights === 1 ) && !approvedSalary.approvedByAdmin) ?
                        <Textarea className="mt-2" onValueChange={setAdminComment} defaultValue={adminComment} />
                        : <Text className="mt-2">{(adminComment.length === 0 ) ? 'Ingen kommentar' : adminComment }</Text> 
                    }
                    </div>
                ) : null 
            }
            <Title className="mt-6">Godkännande</Title>
            
            <div className="">
                <div className="flex flex-row mt-2 items-center">
                    { approvedSalary && (approvedSalary.approvedByDriver) ? <CheckCircleIcon className={`h-6 w-6 mr-2 text-green-500 `} /> : <XCircleIcon className={`h-6 w-6 mr-2 text-yellow-500 `} /> } 
                    <Text>Godkänd av Förare</Text>
                </div>
                <div className="flex flex-row mt-2 items-center">
                    { approvedSalary && (approvedSalary.approvedByAdmin) ? <CheckCircleIcon className={`h-6 w-6 mr-2 text-green-500 `} /> : <XCircleIcon className={`h-6 w-6 mr-2 text-yellow-500 `} /> } 
                    <Text>Godkänd av Administrator</Text>
                </div>   
            </div>
            <div className="mb-6 h-12">
                {
                    getApproveButton()
                }
            </div>
        </div>
    )
}

export default SalaryApprove