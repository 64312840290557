import React from "react";
import { SearchSelectItem,SearchSelect, Text , Grid, Col, DatePicker, DatePickerValue} from '@tremor/react';
import {useAuth} from "../../auth/AuthContext";
import dayjs from "dayjs";
import { Driver } from "../../types/driver";
import { Company } from "../../types/company";
import { Vechile } from "../../types/vechiles";

type  DashboardHeaderProps = {
    setCompanyNo: (companyNo:number) => void,
    setDriverNo:(driverNo:number | null) => void,
    setStartDate: (date:string) => void,
    setEndDate: (date:string) => void,
    companyNo: number |null,
    companies: Company[] | null,
    driverNo: number| null,
    drivers: Driver[] | null,
    startDate: string,
    endDate: string,
    overall: boolean,
    setOverall: (isOverall:boolean) => void,
    carNo: number | null,
    cars: Vechile[] | null,
    setCarNo: (car:number | null) => void,
    setSelected: (selected:number) => void,

}

const DashboardHeader = (props:DashboardHeaderProps) => {

    const {
        setCompanyNo,
        setDriverNo,
        setStartDate,
        setEndDate,
        companyNo,
        companies,
        driverNo,
        drivers,
        startDate,
        endDate,
        overall,
        setOverall,
        carNo,
        cars,
        setCarNo,
        setSelected
    } = props;

    const {user} = useAuth();

    const onChangeDate = (value:DatePickerValue,action:string) => {
        const date = dayjs(value).format('YYYY-MM-DD')
        if(action === 'startdate'){
            setStartDate(date)
        }else{
            setEndDate(date)
        }
    }

    return (
        <div className="">
            <Grid numItems={3} numItemsLg={3} numItemsMd={3} numItemsSm={3}>
                <Col numColSpan={3} numColSpanMd={1} numColSpanSm={3} numColSpanLg={1} >
                    <div className="mt-4 mb-2  mr-6">
                        <Text className="mb-2">Från:</Text>
                        <DatePicker value={ new Date(startDate) } displayFormat="yyyy-MM-dd" className=" mx-auto" onValueChange={(v)=>onChangeDate(v,'startdate')} />
                    </div>
                    <p className="mt-4 text-tremor-label text-tremor-content dark:text-dark-tremor-content">
                    Välj startdatum
                    </p>
                </Col>
                <Col numColSpan={3} numColSpanMd={1} numColSpanSm={3} numColSpanLg={1} >
                    <div className="mt-4 mb-2 mr-6">
                        <Text className="mb-2">Till:</Text>
                        <DatePicker value={ new Date(endDate) } displayFormat="yyyy-MM-dd" className=" mx-auto" onValueChange={(v)=>onChangeDate(v,'enddate')} />
                    </div>
                    <p className="mt-4 text-tremor-label text-tremor-content dark:text-dark-tremor-content">
                    Välj slutdatum
                    </p>
                </Col>
                <Col numColSpan={3} numColSpanMd={1} numColSpanSm={3} numColSpanLg={1}>
                    <div className="mt-4 mb-2 mr-6">
                        <Text className="mb-2">Företag</Text>
                        <SearchSelect value={ companyNo?.toString() || ""} onValueChange={(value)=>setCompanyNo(Number(value))} placeholder="Välj">
                            {companies && 
                                companies.map((company,idx) => (
                                    <SearchSelectItem key={idx} value={company.akareNo.toString()}>
                                        {company.haldaName}
                                    </SearchSelectItem>
                                ))
                            }
                        </SearchSelect>
                    </div>
                    <p className="mt-4 text-tremor-label text-tremor-content dark:text-dark-tremor-content">
                        Välj Företag
                    </p>
                </Col>
                {
                    (user && user.rights === 5) ? null :(
                        <Col numColSpan={3} numColSpanMd={1}  numColSpanSm={3} numColSpanLg={1}>
                            <div className="mt-4 mb-2  mr-6">
                                <Text className="mb-2">Förare</Text>
                                <SearchSelect placeholder="Välj" disabled={overall} value={ driverNo?.toString() || ""} onValueChange={(value)=>{setCarNo(null);setDriverNo(Number(value));setSelected(Number(value))}}>
                                    {drivers &&
                                        drivers.map((driver,idx) => (
                                            <SearchSelectItem key={idx} value={driver && driver.driverNo!.toString()}>
                                                {driver.name} - {driver.driverNo}
                                            </SearchSelectItem>
                                        ))
                                    }
                                </SearchSelect>
                            </div>
                            <p className="mt-4 text-tremor-label text-tremor-content dark:text-dark-tremor-content">
                                Välj Förare
                            </p>
                        </Col>
                    ) 
                }
                {
                    (user && user.rights === 5) ? null :(
                        <Col numColSpan={3}  numColSpanMd={1} numColSpanSm={3} numColSpanLg={1}>
                            <div className="mt-4 mb-2  mr-6">
                                <Text className="mb-2">Bil</Text>
                                <SearchSelect placeholder="Välj" disabled={overall} value={carNo?.toString() || ""} onValueChange={(value)=>{setDriverNo(null);setCarNo(Number(value));setSelected(Number(value))}}>
                                    {cars &&
                                        cars.map((car,idx) => (
                                            <SearchSelectItem key={idx} value={car.carNo.toString()}>
                                                {car.carNo}
                                            </SearchSelectItem>
                                        ))
                                    }
                                </SearchSelect>
                            </div>
                            <p className="mt-4 text-tremor-label text-tremor-content dark:text-dark-tremor-content">
                                Välj bil
                            </p>
                        </Col>
                    ) 
                }
            </Grid>
        </div>
    );
}

export default DashboardHeader;