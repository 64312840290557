import React, { useEffect, useState } from "react";
import { Select, SelectItem, Text, DatePicker, Title, Grid, Col, Button, TextInput, Textarea, Card, Divider, DatePickerValue } from '@tremor/react'
import axios from "axios";
import dayjs from "dayjs";
import { validateTime, calculateTotalHours, formatTime } from "../../lib/utils";
import { useNavigate } from "react-router-dom";
import BreaksList from "./BreaksList";
import AddBreak from "./AddBreak";
import { useAuth } from "../../auth/AuthContext";
import { XMarkIcon } from "@heroicons/react/24/outline";
/* import { v4 as uuidv4 } from 'uuid'; */
import { Break, Report, Shift } from "../../types/shifts";
import { Company } from "../../types/company";
import { Driver } from "../../types/driver";

type NewShiftProps = {
  customShiftDriverNo: number | null,
  closeNewShift: (() => void) | null,
}

const NewShift = (props: NewShiftProps) => {
  const {
    customShiftDriverNo,
    closeNewShift
  } = props

  const { user } = useAuth();
  const [pass, setPass] = useState<Shift | null>(null)
  const [breaks, setBreaks] = useState<Break[] | null>([])

  const [companyNo, setCompanyNo] = useState<number | null>(null)
  const [type, setType] = useState<string | null>(null)
  const [companies, setCompanies] = useState<Company[] | null>([])
  const [date, setDate] = useState<string | null>(null)
  const [hoursWorked, setHoursWorked] = useState<string | null>(null)
  const [drivers, setDrivers] = useState<Driver[] | null>([])
  const [driverNo, setDriverNo] = useState<number | null>(null)
  const [allDay, setAllDay] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const navigate = useNavigate();
  type onChangeType = string | number | boolean | null | undefined;


  const onChange = (value: onChangeType, name: keyof Report) => {
    setPass(prevState => prevState ? ({
      ...prevState,
      report: {
        ...prevState.report,
        [name]: value ?? ""
      }
    }) : null);
  };

  const onChangeDate = (value: DatePickerValue, key: keyof Report) => {
    const newDate = dayjs(value).format('YYYY-MM-DD');
    setPass(prevState => prevState ? ({
      ...prevState,
      report: {
        ...prevState.report,
        [key]: newDate
      }
    }) : null);

    if (key === "startdate") {
      setDate(newDate);
    }
  };

  async function getCompanies() {
    if (!date)
      return;

    const response = await axios.get('/company/available?startdate=' + date);
    setCompanies(response.data);
  }

  useEffect(() => {
    if (customShiftDriverNo) {
      setDriverNo(customShiftDriverNo);
      onChange(customShiftDriverNo, "driverNo");
    }
  }, [customShiftDriverNo]);

  useEffect(() => {
    async function getDrivers() {
      const response = await axios.get('/drivers?akareNo=' + companyNo + '&startdate=' + '2024-01-01'); // Grab all drivers that have driven since the begining of 2024.
      setDrivers(response.data);
    }
    if (companyNo && date)
      getDrivers();
  }, [companyNo, date]);

  useEffect(() => {
    getCompanies();
  }, [date]);

  useEffect(() => {
    if (pass) {
      if ((validateTime(pass.report.starttimeUI!) && validateTime(pass.report.endtimeUI!))) {
        const tempTotal = calculateTotalHours(pass);
        if (typeof tempTotal === 'string' && tempTotal.startsWith('Check values')) {
          setHoursWorked("00:00");
        } else {
          const [hours, minutes] = tempTotal.toString().split(':').map(Number);
          const totalMinutes = (hours * 60) + minutes;
          setHoursWorked(totalMinutes < 0 ? "00:00" : tempTotal.toString());
        }
      }
    }
  }, [pass, breaks]);

  const isDisabled = () => {
    if (!pass || errorMessage) {
      return true;
    }

    const { startdate, enddate, companyID, starttimeUI, endtimeUI, report_type } = pass.report;

    if (!startdate || !enddate || !companyID || !starttimeUI || !endtimeUI || !report_type) {
      return true;
    }

    // Validate start and end time formats
    if (!validateTime(starttimeUI) || !validateTime(endtimeUI)) {
      return true;
    }

    // Validate break times
    if (breaks && breaks.some(b => !validateTime(b.starttime) || !validateTime(b.endtime))) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (pass?.report.startdate && pass?.report.enddate) {
      const formattedStartDate = dayjs(pass.report.startdate).format('YYYY-MM-DD');
      const formattedEndDate = dayjs(pass.report.enddate).format('YYYY-MM-DD');

      if (dayjs(formattedStartDate).isAfter(dayjs(formattedEndDate))) {
        setErrorMessage("Startdatum kan inte vara efter slutdatum.");
      } else {
        setErrorMessage(null);
      }
    }
  }, [pass?.report.startdate, pass?.report.enddate]);

  useEffect(() => {
    if (type === "2") {
      // "Semester"
      setPass({
        ...pass,
        report: {
          ...pass?.report,
          starttimeUI: "08:00",
          endtimeUI: "16:00",
        }
      } as Shift);
      setHoursWorked("8:00");
      onChange("8:00", 'hoursWorked');
      setAllDay(true); // lock the hours
    } else if (allDay || type === "3") {
      // "Sick" or "All Day" selected
      setPass({
        ...pass,
        report: {
          ...pass?.report,
          starttimeUI: "08:00",
          endtimeUI: "16:00",
        }
      } as Shift);;
      setHoursWorked("8:00");
      onChange("8:00", 'hoursWorked');
    } else {
      setPass({
        ...pass,
        report: {
          ...pass?.report,
          starttimeUI: "00:00",
          endtimeUI: "00:00",
        }
      } as Shift);
      setHoursWorked("0:00");
      onChange("0:00", 'hoursWorked');
    }
  }, [type, allDay]);


  const createShift = async () => {
    if (!pass || errorMessage) {
      return;
    }
  
    try {
      // Check if required fields are filled
      if (!pass.report.companyID || !pass.report.enddate || !pass.report.startdate || !pass.report.starttimeUI || !pass.report.endtimeUI) {
        alert("Var god fyll i alla fält");
        return;
      }
  
      // Prepare updated pass object
      const updatedPass = {
        ...pass,
        report: {
          ...pass.report,
          hoursWorked: hoursWorked || "0:00",
          driverNo: Number(driverNo),
        },
        breaks: breaks || [],
      };
  
      // Make the API request to create the shift
      const response = await axios.post(`/shifts`, updatedPass);
  
      // If the request was successful (2xx status), proceed with further actions
      if (response.status === 200) {
        if (customShiftDriverNo) {
          closeNewShift && closeNewShift();
        } else {
          navigate('/app/shifts');
        }
      }
      
    } catch (err: any) {
      // Handle error from axios
      if (err.response) {
        // This block handles 4xx or 5xx status codes returned by the backend
        console.log('Error response from backend:', err.response.data);
        if (err.response.data.error) {
          alert(`Error: ${err.response.data.error}`);
        } else {
          alert('An error occurred. Please try again.');
        }
      } else if (err.request) {
        // This block handles errors where no response was received (e.g., network issues)
        console.log('Error: No response received from server', err.request);
        alert('No response received from the server.');
      } else {
        // Handle other errors
        console.log('Unexpected error:', err.message);
        alert(`An unexpected error occurred: ${err.message}`);
      }
    }
  };

  const removeBreak = (tmpId: string) => {
    if (breaks) {

      let newBreaks = breaks.filter((b, idx) => b.tmpId !== tmpId);
      setBreaks(newBreaks);
    }
  }

  const addBreak = (starttime: string, endtime: string) => {
    if (!starttime || !endtime) return;
    let newBreaks: Break[] = [];

    breaks?.forEach((brk) => { newBreaks.push(brk) });
    setBreaks(newBreaks);
  }

  return (
    <div className="container mx-auto w-[80%] mt-6 ">
      <div className="pb-12">
        <Card className="pb-16">
          <XMarkIcon className="w-6 h-6 text-gray-500 cursor-pointer mb-6 float-right" onClick={() => {
            if (customShiftDriverNo) { closeNewShift && closeNewShift() } else { window.history.back() }
          }} />
          <Title className="mb-6">Ny Rapport</Title>

          <Grid
            numItems={4}
            numItemsSm={4}
            numItemsMd={4}
            numItemsLg={4}
            className="gap-2"
          >
            <Col numColSpan={4} numColSpanMd={2} numColSpanLg={2}>
              <div className="mt-4 mb-2 ">
                <Text className="mb-2">Startdatum *</Text>
                <DatePicker
                  defaultValue={pass?.report.startdate}
                  placeholder="Välj datum"
                  displayFormat="yyyy-MM-dd"

                  className=""
                  onValueChange={(v) => onChangeDate(v, "startdate")}
                />
              </div>
            </Col>
            <Col numColSpan={4} numColSpanMd={2} numColSpanLg={2}>
              <div className="mt-4 mb-2 ">
                <Text className="mb-2">Slutdatum *</Text>
                <DatePicker
                  defaultValue={pass?.report.enddate}
                  placeholder="Välj datum"
                  displayFormat="yyyy-MM-dd"
                  className=""
                  onValueChange={(v) => onChangeDate(v, "enddate")}
                />
              </div>
            </Col>

            {errorMessage && (
              <Col numColSpan={4}>
                <Text className="text-red-500">{errorMessage}</Text>
              </Col>
            )}

            <Col numColSpan={4} numColSpanMd={2} numColSpanLg={2}>
              <div className="mt-4 mb-2">
                <Text className="mb-2">Företag *</Text>
                <Select
                  value={companyNo?.toString()}
                  placeholder="Välj"
                  onValueChange={(value) => {
                    setCompanyNo(Number(value));
                    onChange(value, "companyID");
                  }}
                >
                  {companies && companies.map((company, idx) => (
                    <SelectItem key={idx} value={company.akareNo.toString()}>
                      {company.haldaName}
                    </SelectItem>
                  ))}
                </Select>
                <p className="mt-2 text-tremor-label text-tremor-content dark:text-dark-tremor-content">
                  Välj Företag
                </p>
              </div>
            </Col>
            {user && user.rights !== 5 ? (
              <Col
                numColSpan={3}
                numColSpanMd={2}
                numColSpanSm={3}
                numColSpanLg={2}
              >
                <div className="mt-4 mb-2 mr-6">
                  <Text className="mb-2">Förare *</Text>
                  <Select
                    value={driverNo?.toString() || ""}
                    placeholder="Välj"
                    onValueChange={(value) => setDriverNo(Number(value))}
                  >
                    {drivers && drivers.map((driver, idx) => (
                      <SelectItem key={idx} value={driver.driverNo?.toString() || ""}>
                        {driver.name} - {driver.driverNo}
                      </SelectItem>
                    ))}
                  </Select>
                  <p className="mt-2 text-tremor-label text-tremor-content dark:text-dark-tremor-content">
                    Välj Förare
                  </p>
                </div>
              </Col>
            ) : null}

            <Col numColSpan={4} numColSpanMd={4} numColSpanLg={2}>
              <div className="mt-4 mb-2">
                <Text className="mb-2">Typ: *</Text>
                <Select
                  value={type ?? "NaN"}
                  placeholder="Välj"
                  onValueChange={(value) => {
                    setType(value);
                    onChange(value, "report_type");
                    setAllDay(value === "2" || value === "3" ? true : false);
                  }}
                >
                  <SelectItem value={"2"}>Semester</SelectItem>
                  <SelectItem value={"3"}>Sjuk</SelectItem>
                  <SelectItem value={"4"}>Övrigt</SelectItem>
                </Select>
                <p className="mt-2 text-tremor-label text-tremor-content dark:text-dark-tremor-content">
                  Typ av rapport ( Semester, Sjuk, Övrigt )
                </p>
                {(type === "3" || type === "2") && (
                  <label
                    htmlFor="allDayCheckbox"
                    className="flex items-center space-x-2 pt-3"
                  >
                    <input
                      type="checkbox"
                      id="allDayCheckbox"
                      name="allDay"
                      checked={allDay}
                      onChange={(e) => setAllDay(e.target.checked)}
                      className="w-4 h-4 border border-gray-300 rounded-sm focus:ring-gray-500 text-tremor-label"
                      disabled={type === "2"} // Disable in case of "Semester"
                    />
                    <span className="text-tremor-label">Hela Dagen</span>
                  </label>
                )}
              </div>
            </Col>
            <Col numColSpan={4} numColSpanMd={4} numColSpanLg={2}>
              <div className="mt-4 mb-2 mr-6">
                <Text className="mb-2">
                  Aktivitet {type !== "2" && type !== "3" && "*"}
                </Text>
                <TextInput
                  onValueChange={(value) => onChange(value, "activity")}
                  placeholder="Ange"
                  disabled={type === "2" || type === "3"}
                />
              </div>
            </Col>
            <Col numColSpan={4} numColSpanMd={4} numColSpanLg={2}>
              <div className="mt-4 mb-2 mr-6">
                <Text className="mb-2">Fordon</Text>
                {pass &&
                  <TextInput
                    maxLength={4}
                    disabled={type === "2" || type === "3"}
                    type="text"
                    placeholder="Bil nr"
                    onValueChange={(value) => onChange(value, "carNo")}
                  />
                }
              </div>
            </Col>

            <Col numColSpan={4}>
              <Divider>Tid</Divider>
            </Col>
            <Col numColSpan={4} numColSpanMd={2}>
              <div className="mt-4 mb-2">
                <Text className="mb-2">Start</Text>
                {pass &&
                  <>
                    <TextInput
                      placeholder="00:00"
                      error={!validateTime(formatTime(pass.report.starttimeUI?.toString() || ""))}
                      errorMessage={"Var vänlig att ange tiden i formatet: TT:MM"}
                      value={formatTime(pass.report.starttimeUI?.toString() || "")}
                      onValueChange={(value) => onChange(value, "starttimeUI")}
                      disabled={allDay || type === "2"}
                    />
                  </>
                }
              </div>
            </Col>
            <Col numColSpan={4} numColSpanMd={2}>
              <div className="mt-4 mb-2">
                <Text className="mb-2">Slut</Text>
                {pass &&
                  <TextInput
                    placeholder="00:00"
                    error={!validateTime(formatTime(pass.report.endtimeUI?.toString() || ""))}
                    errorMessage={"Var vänlig att ange tiden i formatet: TT:MM"}
                    value={formatTime(pass.report.endtimeUI?.toString() || "")}
                    onValueChange={(value) => onChange(value, "endtimeUI")}
                    disabled={allDay || type === "2"}
                  />
                }
              </div>
            </Col>
            {pass && pass.report_type !== 1 ? null : (
              <>
                <Col numColSpan={4}>
                  <Divider>Rast</Divider>
                </Col>
                <BreaksList breaks={breaks} removeBreak={removeBreak} />
                <AddBreak addBreak={addBreak} />
              </>
            )}
            <Col numColSpan={4}>
              <div className="mt-4 mb-2 mr-6">
                <Text className="mb-2">Arbetad tid </Text>
                <Text>{hoursWorked || "00:00"}</Text>
              </div>
            </Col>

            <Col numColSpan={4}>
              <Divider>Reportering</Divider>
            </Col>
            <Col numColSpan={4}>
              <Text>Avvikelser / Felrapportering</Text>
              <div className="mt-4 mb-6">
                <Text className="mb-2">Ekonomi/Lön/ADM</Text>
                {pass &&
                  <Textarea
                    placeholder=""
                    value={pass.report.errorReportAdm}
                    onChange={(e) => onChange(e.target.value, "errorReportAdm")}
                  />
                }
              </div>
            </Col>

            <Col numColSpan={4}>
              <Text className="">Felanmälan</Text>
              <div className="mt-4 mb-6">
                <Text className="mb-2">Fordon / Teknik / Tvätthall</Text>
                {pass &&
                  <Textarea
                    placeholder=""
                    className="mb-6"
                    value={pass.report.errorReportVehicle}
                    onChange={(e) =>
                      onChange(e.target.value, "errorReportVehicle")
                    }
                  />
                }
              </div>
            </Col>
          </Grid>
          <Button
            size="xs"
            className="float-right"
            disabled={isDisabled()}
            onClick={() => createShift()}
          >
            Skicka in
          </Button>
        </Card>
      </div>
    </div>
  );
};
export default NewShift;
