import React from 'react'
import { TextInput, Button } from '@tremor/react'

type RegisterMoneyProps = {
    amount: number,
    setAmount: (newAmount: number) => void,
    registerMoney: () => void
    disabled: () => boolean
}

const RegisterMoney = (props: RegisterMoneyProps) => {


    const {
        amount,
        setAmount,
        registerMoney,
        disabled
    } = props

    return (
        <div className= "mt-4 mb-2 w-1/5 mr-6" >
        <TextInput type="number" name = "amount" placeholder = "Amount for money" value = { amount.toString() } onValueChange = {(value) => setAmount(value) } />
            <Button className="mt-4" disabled = { disabled() } size = "xs" onClick = { registerMoney } > Registrera pengar </Button>
        </div>
    )
}

export default RegisterMoney
