import React from "react";
import { Grid, Col, Divider} from "@tremor/react";
import CardLineChart from "./LineChart";
import CardDoughnutChart from "./DoughnutChart";
import TotalMetric from "./TotalMetric";
import { convertToCurrency } from "../../lib/utils";
import { VehicleStatsOverall } from "../../../../shared/types/vechileStats"

type OverAllViewProps = {
    statsData: VehicleStatsOverall | null,
    moms: boolean
}

const OverAllView = (props:OverAllViewProps) => {
    
    const {
        statsData,
        moms
    } = props

    return (
        <Grid  numItems={4}>
             {
                (statsData?.totaltoPay) ? (
                    <>
                        <Col numColSpan={4}>
                            <Divider>Inkört belopp</Divider>
                        </Col>
                        <CardLineChart
                            index={"day"}
                            title={`Inkört ${(moms) ? 'Inkl. moms' : 'Exkl. moms'}`}
                            labels={[(moms) ? "Inkört inkl moms" : "Inkört exkl moms", "bom"]}
                            valueFormatter={(number) => `${convertToCurrency(number)}`}
                            data={statsData?.totaltoPay} />
                    </>
                ) : null
            }
            {(statsData?.totals?.bom || statsData?.totals?.sumHardCash  || statsData?.totals?.totMadeInkTax) && (
              <>
                  {statsData?.totals?.bom && (
                      <Col numColSpan={1}>
                          <TotalMetric
                              value={(moms) ? convertToCurrency(statsData?.totals.bom) : convertToCurrency(statsData?.totals.bom - (statsData?.totals.bom * 0.06))}
                              title={"Totalt Bom"}
                              undertext={`${moms ? 'Inkl. moms' : 'Exkl. moms'}`}
                          />
                  </Col>)
          }
           {statsData?.totals?.sumHardCash  && (
                        <Col numColSpan={2}>
                            <TotalMetric
                                value={(moms) ? convertToCurrency(statsData?.totals.sumHardCash ) : convertToCurrency(statsData?.totals.sumHardCash  / 1.06)}
                                title={"Kontanter, inkört"}
                                undertext={`${moms ? 'Inkl. moms' : 'Exkl. moms'}`}
                            />
                        </Col>
                    )}
                    {statsData?.totals?.totMadeInkTax && (
                        <Col numColSpan={1}>
                            <TotalMetric
                                value={(moms) ? convertToCurrency(statsData?.totals.totMadeInkTax) : convertToCurrency(statsData?.totals.sumHardCash )}
                                title={"Totalt inkört"}
                                undertext={`${moms ? 'Inkl. moms' : 'Exkl. moms'}`}
                            />
                        </Col>
                    )}
                </>
            )}
            {
                (statsData?.totaltoPay) ? (
                    <>
                        <Col numColSpan={4}>
                            <Divider>Sträcka</Divider>
                        </Col>
                        <CardLineChart index={"day"} 
                            title="Sammanställning mil" 
                            labels={["Totalt antal mil", "Totalt antal betalda mil","Totalt antal obetalda mil"]} 
                            data={statsData?.totaltoPay.map((day)=>{day['Totalt antal obetalda mil'] = (day['Totalt antal betalda mil']-day['Totalt antal mil']);return day;})} 
                            valueFormatter={(value)=>`${value} mil`}
                        />
                    </>
                ) : null
            }

            {
                (statsData?.totaltoPay) ? (
                    <>
                        <Col numColSpan={4}>
                            <Divider>Tid</Divider>
                        </Col>
                        <CardLineChart index={"day"} 
                            title="Sammanställning timmar" 
                            labels={["Totalt antal timmar", "Totalt antal Rast timmar"]} 
                            data={statsData?.totaltoPay} 
                            valueFormatter={(value)=>`${value} timmar`}
                        />
                    </>
                ) : null
            }

{
                (statsData?.totals?.hoursWorked) ? (
                    <Col numColSpan={2}>
                        <CardDoughnutChart 
                            index={"name"} 
                            category={"value"} 
                            title="Timmar" 
                            valueFormatter={ (number:number) => `${Math.round(number)} timmar` }
                            data={[
                                {name : "Betalda timmar", value: parseInt(statsData.totals.paidTime) },
                                {name : "Obetalda timmar", value: (statsData.totals.totalBreaks)+(statsData.totals.hoursWorked)-parseInt(statsData.totals.paidTime)} , 
                            ]} />
                    </Col>
                ) : null
            }

{
                (statsData?.totals?.distanceDone) ? (
                    <Col numColSpan={2}>
                        <CardDoughnutChart 
                            index={"name"} 
                            category={"value"} 
                            title="Totala betalda mil " 
                            valueFormatter={ (number:number) => `${Math.round(number)} mil` }
                            data={[{name : "Betalda mil", value: parseInt(statsData.totals.distancePaid)} , {name : "Obetalda mil", value: parseInt(statsData.totals.distanceDone)-parseInt(statsData.totals.distancePaid)} , ]} />
                    </Col>
                ) : null

            }         
           
        </Grid>
    );

}

    export default OverAllView;
