import React from 'react';
import { Title , Card, LineChart, Col} from '@tremor/react';
import { TotalDriven, TotalMoney, TotalTime, TotaltoPay } from '../../../../shared/types/vechileStats';

type CardLineChartProps = {
    data: TotalTime[] | TotalDriven[] | TotalMoney[] | TotaltoPay[],
    labels: string [],
    title:string,
    index: string,
    valueFormatter?: (number:number) => string
}


const CardLineChart = (props:CardLineChartProps) => {
    const {
        data,
        labels,
        title,
        index,
        valueFormatter
    } = props
    
    return (
        <Col numColSpan={4} className="mt-4 mb-4">
            <Card className="">
                <Title>{title}</Title>
                <LineChart
                    className="mt-6"
                    data={data}
                    index={index}
                    categories={labels}
                    colors={["emerald","cyan", "orange"]}
                    valueFormatter={valueFormatter || ((number) => `${number}`)}
                    yAxisWidth={100}
                    />
            </Card>
        </Col>
    )
}

export default CardLineChart;
