import React, { useState } from "react";
import {
Card,
Table,
TableBody,
TableHead,
TableHeaderCell,
TableRow,
Title,
Button
} from "@tremor/react";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline";
import SalaryTableRow from "./SalaryTableRow";
import { getFractionalHour , parseDecimalToToTime} from "../../lib/utils";
import { ArrowLongRightIcon, ArrowLongLeftIcon } from "@heroicons/react/24/outline";
import { SalaryData } from "../../types/salaryData";
import { useAuth } from "../../auth/AuthContext";
/* import { Accepted_Correction } from "../../types/corrections"; */

const PROVISION_LON = 0.37;

type SalaryTableProps = {
    salaryData:SalaryData,
/*     approvedCorrections: Accepted_Correction[] | null,
    removeCorrection: (correctionId:number) => void, */
    goToNextDriver: (direction:number) => void,
    userRights: number | null
}

const SalaryTable = (props: SalaryTableProps) => {

    const {
        salaryData,
/*         approvedCorrections,
        removeCorrection, */
        goToNextDriver,
        userRights
    } = props;

    const { user } = useAuth();

    const [seeDetails, setSeeDetails] = useState(false);
    const [seeExtraData, setSeeExtraData] = useState(user && user.rights === 6 ? false : true);

   /*  const getCorrectionsTotal = () => {
        if (!approvedCorrections || approvedCorrections.length === 0) {
          return 0;
        }
        return approvedCorrections
          .map((c) => c.OriginalValue - c.NewValue)
          .reduce((a, b) => a + b, 0);
      }; */
    
      const onSeeDetails = () => {
        setSeeDetails(!seeDetails);
      };
    
      const getProvisionSalary = () => {
        const moms = 0.0566;
        let total = 0;
    
        total =
          (salaryData.cash["totMoneyMadeInkTaxUI"] -
            salaryData.cash["totMoneyMadeInkTaxUI"] * moms) *
          PROVISION_LON;
        return total;
      };
    
      if (!salaryData) {
        return null;
      }
    return (
        <div className="mt-4 mb-2 w-full mr-6">
      <div className="flex flex-row justify-between">
        <div className="w-96">
          <Title className="mb-6 mt-3 min-w-64">{salaryData.driver.name}</Title>
        </div>
        {userRights === 5 ? null : (
          <div>
            <Button
              className="h-8 mt-3 ml-3 p-2"
              variant="primary"
              onClick={() => goToNextDriver(-1)}
              icon={ArrowLongLeftIcon}
            ></Button>
            <Button
              className="h-8 mt-3 ml-3 p-2"
              variant="primary"
              onClick={() => goToNextDriver(1)}
              icon={ArrowLongRightIcon}
            ></Button>
          </div>
        )}
      </div>
      <Card>
        <Table className="">
          <TableHead>
            <TableRow>
              <TableHeaderCell>Namn</TableHeaderCell>
              <TableHeaderCell>Värde</TableHeaderCell>
              <TableHeaderCell>Decimalt värde</TableHeaderCell>
              {/* <TableHeaderCell>Godkänd</TableHeaderCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {user && user.rights === 6 ? (
              <Button
                icon={seeExtraData ? ChevronUpIcon : ChevronDownIcon}
                iconPosition="right"
                variant="secondary"
                className="text-xs ml-4 mb-2"
                size="xs"
                onClick={() => setSeeExtraData(!seeExtraData)}
              >
                Extra Detaljer
              </Button>
            ) : null}
            {seeExtraData ? (
              <>
                <SalaryTableRow
                  style
                  rowData={{
                    label: "Inkört inkl moms (efter korrigeringar)",
                    value: salaryData.cash["totMoneyMadeInkTaxUI"],
                    alternateValue: null,
                    type: "currency",
                   /*  approved: salaryData.approved?.cash.totMoneyMadeInkTaxUI, */
                  }}
                />
                <SalaryTableRow
                  rowData={{
                    label: "Inkört inkl moms (från taxameter)",
                    value: salaryData.cash["totMoneyMadeInkTax"],
                    alternateValue: null,
                    type: "currency",
                   /*  approved: salaryData.approved?.cash.totMoneyMadeInkTaxUI, */
                  }}
                />
                <SalaryTableRow
                  style
                  rowData={{
                    label: "Inkört exkl moms (från taxameter)",
                    value: salaryData.cash["totMoneyMade"],
                    alternateValue: null,
                    type: "currency",
                    /* approved: salaryData.approved?.cash.totMoneyMadeUI, */
                  }}
                />
                <SalaryTableRow
                  rowData={{
                    label: "ProvisonLön",
                    value: getProvisionSalary(),
                    alternateValue: null,
                    type: "currency",
                    /* approved:
                      parseFloat(salaryData.approved?.cash.totMoneyMadeUI) *
                      PROVISION_LON, */
                  }}
                />
                <SalaryTableRow
                  style
                  rowData={{
                    label: "BOM Totalt",
                    value: salaryData["bom"],
                    alternateValue: null,
                    type: "currency",
                    /* approved: salaryData.approved?.bom, */
                  }}
                />
                <SalaryTableRow
                  rowData={{
                    label: "Inkört per timme exkl moms",
                    value: salaryData["revenuePerHour"],
                    alternateValue: null,
                    type: "currency",
                    /* approved: salaryData.approved?.revenuePerHour, */
                  }}
                />
                <SalaryTableRow
                  style
                  rowData={{
                    label: "Inkört per mil exkl moms",
                    value: salaryData["revenuePerSeMile"],
                    alternateValue: null,
                    type: "currency",
                    /* approved: salaryData.approved?.revenuePerSeMile, */
                  }}
                />
              </>
            ) : null}
                        <SalaryTableRow
              badge={salaryData["manualPassesTotal"]}
              rowData={{
                label: "Inloggad tid ( från taxameter )",
                value: salaryData["totWorkedData"]["totTid"],
                alternateValue: getFractionalHour(
                  salaryData["totWorkedData"]["totTid"]
                ),
                /* approved:
                  salaryData.approved?.approvedTotWorkedData?.totalTidUI, */
              }}
            />
            <SalaryTableRow
              style
              rowData={{
                label: "Rast (från taxameter )",
                value: salaryData["totWorkedData"]["totBreaktime"],
                alternateValue: getFractionalHour(
                  salaryData["totWorkedData"]["totBreaktime"]
                ),
                /* approved:
                  salaryData.approved?.approvedTotWorkedData?.totBreaktime, */
              }}
            />
            <SalaryTableRow
              rowData={{
                label: "Inloggad taxameter minus rast ( från taxameter )",
                value: salaryData["totWorkedData"]["hoursWorked"],
                alternateValue: getFractionalHour(
                  salaryData["totWorkedData"]["hoursWorked"]
                ),
                /* approved:
                  salaryData.approved?.approvedTotWorkedData?.hoursWorked, */
              }}
            />
            <SalaryTableRow
              style
              rowData={{
                label: "Totalt timmarlön ( med manuell pass)",
                value: salaryData["totalTotWorkedData"]["hoursWorkedUI"],
                alternateValue: getFractionalHour(
                  salaryData["totalTotWorkedData"]["hoursWorkedUI"]
                ),
                /* approved:
                  salaryData.approved?.totalApprovedWorkedTime?.hoursWorkedUI, */
              }}
              trigger={onSeeDetails}
              seeDetails={seeDetails}
            />
            {seeDetails && (
                <>
                    <SalaryTableRow
                    rowData={{
                        label: " - Kör",
                        value:
                        Array.isArray(salaryData["manualPasses"]) &&
                        salaryData["manualPasses"].find(
                            (record) => record.report_type === 1
                        )?.hoursWorked || "0",
                        alternateValue: getFractionalHour(
                        Array.isArray(salaryData["manualPasses"]) &&
                        salaryData["manualPasses"].find(
                            (record) => record.report_type === 1
                        )?.hoursWorked || "0"
                        ),
                       /*  approved:
                        salaryData.approved?.totalApprovedWorkedTime
                            ?.hoursWorkedUI || "N/A", */
                    }}
                    />
                    <SalaryTableRow
                    style
                    rowData={{
                        label: " - Semester",
                        value:
                        Array.isArray(salaryData["manualPasses"]) &&
                        salaryData["manualPasses"].find(
                            (record) => record.report_type === 2
                        )?.hoursWorked || "0",
                        alternateValue: getFractionalHour(
                        Array.isArray(salaryData["manualPasses"]) &&
                        salaryData["manualPasses"].find(
                            (record) => record.report_type === 2
                        )?.hoursWorked || "0"
                        ),
                        /* approved:
                        salaryData.approved?.totalApprovedWorkedTime
                            ?.hoursWorkedUI || "N/A", */
                    }}
                    />
                    <SalaryTableRow
                    rowData={{
                        label: " - Sjuk",
                        value:
                        Array.isArray(salaryData["manualPasses"]) &&
                        salaryData["manualPasses"].find(
                            (record) => record.report_type === 3
                        )?.hoursWorked || "0",
                        alternateValue: getFractionalHour(
                        Array.isArray(salaryData["manualPasses"]) &&
                        salaryData["manualPasses"].find(
                            (record) => record.report_type === 3
                        )?.hoursWorked || "0"
                        ),
                        /* approved:
                        salaryData.approved?.totalApprovedWorkedTime
                            ?.hoursWorkedUI || "N/A", */
                    }}
                    />
                    <SalaryTableRow
                    style
                    rowData={{
                        label: " - Övrigt",
                        value:
                        Array.isArray(salaryData["manualPasses"]) &&
                        salaryData["manualPasses"].find(
                            (record) => record.report_type === 4
                        )?.hoursWorked || "0",
                        alternateValue: getFractionalHour(
                        Array.isArray(salaryData["manualPasses"]) &&
                        salaryData["manualPasses"].find(
                            (record) => record.report_type === 4
                        )?.hoursWorked || "0"
                        ),
                        /* approved:
                        salaryData.approved?.totalApprovedWorkedTime
                            ?.hoursWorkedUI || "N/A", */
                    }}
                    />
                </>
            )}
            <SalaryTableRow
              rowData={{
                label: "OB1",
                value: parseDecimalToToTime(
                  salaryData.obHours["ob1"] < 0 ? 0 : salaryData.obHours["ob1"]
                ),
                alternateValue:
                  salaryData.obHours["ob1"] < 0 ? 0 : salaryData.obHours["ob1"],
                /* approved: parseDecimalToToTime(
                  salaryData.approved?.obHours?.ob1
                ), */
              }}
            />
            <SalaryTableRow
              style
              rowData={{
                label: "OB2",
                value: parseDecimalToToTime(salaryData.obHours["ob2"]),
                alternateValue: salaryData.obHours["ob2"],
                /* approved: parseDecimalToToTime(
                  salaryData.approved?.obHours?.ob2
                ), */
              }}
            />
            <SalaryTableRow
              rowData={{
                label: "Kontant, Inlämnat",
                value: salaryData.cash["cash"],
                alternateValue: null,
                type: "currency",
                /* approved: salaryData.approved?.cash.cash, */
              }}
            />
            <SalaryTableRow
              style
              rowData={{
                label: "Kontant, Inkört",
                value: salaryData.cash["totAmCash"],
                alternateValue: null,
                type: "currency",
              }}
            />
            <SalaryTableRow rowData={ { label : "Kvar att redovisa" , value : salaryData.cash['Sum'], alternateValue : null ,type : "currency",  approved : salaryData.approved?.cash.Sum} } />
          </TableBody>
        </Table>
      </Card>
    </div>
  );
};

export default SalaryTable;