import React, {useEffect, useState} from 'react';
import { NavLink , useNavigate} from 'react-router-dom';
import { useAuth } from '../auth/AuthContext';
import { CpuChipIcon, ArrowRightOnRectangleIcon} from '@heroicons/react/24/outline'
import {Tab, TabGroup, TabList} from '@tremor/react'
import { tabs } from '../config/tabs';
import { APP_ROUTES } from '../config/routes';
import { User } from '../types/user';

const NavBar = () => {  
    const {user} = useAuth()
    const navigate = useNavigate();
    const [isMenuOpen, setMenuOpen] = useState(false);
    const [active, setActive] = useState<number>(0);

    useEffect(() => {
        if(user){
            const path = window.location.pathname;
            tabs.filter((t)=>(
                t.rights.includes(user.rights)
            )).find((t, index) => {
                if(path === t.href){
                    setActive(index)
                }
            })
        }
    },[navigate]);

    if(!user)
        return null;

    return (
        <div className="h-16 flex flex-row justify-center items-center">
            <TabGroup index={active}> 
                <TabList variant="line" defaultValue="1" >
                <CpuChipIcon className="h-6 w-6 ml-6 mt-1 text-blue-600"></CpuChipIcon>
                <>
                    {
                        tabs.filter((t)=>(
                            t.rights.includes(user.rights)
                        )).map((tab, index) => (
                            <NavLink key={index} to={tab.href}>
                                <Tab value={index}>
                                    {tab.name}
                                </Tab>
                            </NavLink>
                        ))
                    }
                    </>
                    <NavLink to={"https://schema.taxibud.se/rsdb.exe?op=pg_login"}>
                        <Tab value="schema" >
                        Schema
                        </Tab>
                    </NavLink>
                </TabList>
            </TabGroup>
            <div className="mr-6 w-1/6 cursor-pointer mt-1 pb-3 border-b text-sm text-stone-500 hover:text-blue-500 justify-end flex flex-row items-center">
                <NavLink to={APP_ROUTES.SIGNOUT}>
                Logga ut
                </NavLink>
                <ArrowRightOnRectangleIcon className="h-4 w-4 mr-3 text-stone-600 hover:stroke-blue-500"></ArrowRightOnRectangleIcon>
            </div>
        </div>
    );
}

export default NavBar;
