import React from 'react'
import { Dialog, DialogPanel, Button } from '@tremor/react'

type RemoveUserDialog = {
    isOpen: boolean,
    removeUser: (userId:number) => void,
    setIsOpen: (isOpen:boolean) => void,
    selectedUser: number | null;
    setSelectedUser: (userId:number | null) => void


}

const RemoveUserDialog = (props:RemoveUserDialog) => {

    const {
        isOpen,
        removeUser,
        setIsOpen,
        selectedUser,
        setSelectedUser
    } = props


    return (
        <Dialog open={isOpen} onClose={(val) => setIsOpen(val)} static={true}>
        <DialogPanel>
            <h3 className="text-lg font-semibold text-tremor-content-strong dark:text-dark-tremor-content-strong">Ta bort användare</h3>
            <p className="mt-2 leading-6 text-tremor-default text-tremor-content dark:text-dark-tremor-content">
                Är du säker? Denna åtgärd är oåterkallelig.
            </p>
            <Button className="mt-4 ml-2 float-right hover:bg-green-500" size="xs" color={"green"} onClick={() => removeUser(selectedUser!)}>Ja</Button>
            <Button className="mt-4 float-right hover:bg-red-500" size="xs" color={"red"} onClick={() => {setIsOpen(false); setSelectedUser(null) }}>Nej</Button>
        </DialogPanel>
        </Dialog>
    )
}

export default RemoveUserDialog