import { Outlet } from "react-router-dom";
import NavBar from "../components/NavBar";
//@ts-ignore
import { NotificationContainer } from "react-notifications";
import { useAuth } from "../auth/AuthContext";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import React from "react";

const AppLayout = () => {
  const { isAuthenticated, user } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!user || !isAuthenticated) {
      console.log("Not authenticated");
      navigate("/signin");
      return;
    }
  }, [isAuthenticated, navigate, user]);

  return (
    <div className="">
      <NavBar />
      <NotificationContainer />
      <Outlet />
    </div>
  );
};

export default AppLayout;
